import Parse from "parse";

export interface TechnicanAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  name: string;
  name2?: string;
  no?: string;
  searchIndex: string;
  sqlCreatedAt?: Date;
  sqlDeleted: boolean;
  sqlRef: string;
  sqlUpdatedAt?: Date;
}

export class Technican extends Parse.Object<TechnicanAttributes> {
  static className: string = "Heinzerling2_Technican";

  constructor(data?: Partial<TechnicanAttributes>) {
    super("Heinzerling2_Technican", data as TechnicanAttributes);
  }

  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get name2(): string | undefined {
    return super.get("name2");
  }
  set name2(value: string | undefined) {
    super.set("name2", value);
  }
  get no(): string | undefined {
    return super.get("no");
  }
  set no(value: string | undefined) {
    super.set("no", value);
  }
  get searchIndex(): string {
    return super.get("searchIndex");
  }
  set searchIndex(value: string) {
    super.set("searchIndex", value);
  }
  get sqlCreatedAt(): Date | undefined {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value: Date | undefined) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted(): boolean {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value: boolean) {
    super.set("sqlDeleted", value);
  }
  get sqlRef(): string {
    return super.get("sqlRef");
  }
  set sqlRef(value: string) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt(): Date | undefined {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value: Date | undefined) {
    super.set("sqlUpdatedAt", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_Technican", Technican);
