import { message } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import { Item, OrderItemRelation } from "../../../parse";
import { $heinzerling } from "../../../service";
import { OrderState } from "../../orders";

export class UpdateItemState {
  private orderstate: OrderState;
  private items: Item[] = [];
  private _open: boolean = false;
  private _item: Item | null = null;
  private _quantity: number | null = null;
  private _itemrelation: OrderItemRelation | null = null;
  private _optionsSearch = "";

  constructor(orderstate: OrderState) {
    makeAutoObservable(this);
    this.orderstate = orderstate;
  }

  public get open(): boolean {
    return this._open;
  }

  public set open(v: boolean) {
    this._open = v;
  }

  public get quantity(): UpdateItemState["_quantity"] {
    return this._quantity;
  }

  public set quantity(v: UpdateItemState["_quantity"]) {
    this._quantity = v;
  }

  public get item(): UpdateItemState["_item"] {
    return this._item;
  }

  public set item(v: UpdateItemState["_item"] | string) {
    if (typeof v === "string") {
      const item = this.items.find((i) => i.id === v) || null;

      this._item = item;
    } else {
      this._item = v;
    }
  }

  public get itemrelation(): UpdateItemState["_itemrelation"] {
    return this._itemrelation;
  }

  public set itemrelation(v: UpdateItemState["_itemrelation"]) {
    this._itemrelation = v;

    this._item = v?.item || null;
    this._quantity = v?.local_quantity ?? v?.quantity ?? 0;

    this.fetchItems();
  }

  get itemOptions() {
    let items = this.items;

    if (this._optionsSearch === "") {
      items = items.filter((item) => item.inAutofill);
    }

    return this.items.map((item) => ({
      value: item.id,
      label: `[${item.no}] ${item.name}`,
    }));
  }

  public get optionsSearch(): UpdateItemState["_optionsSearch"] {
    return this._optionsSearch;
  }

  public set optionsSearch(v: UpdateItemState["_optionsSearch"]) {
    this._optionsSearch = v;
  }

  public async save() {
    if (!this._itemrelation) {
      return message.error(
        "Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu."
      );
    }

    if (this._item === null) {
      return message.error("Es muss ein Artikel ausgewählt werden!");
    }

    if (this._quantity === null) {
      return message.error("Es muss eine Menge eingegeben werden!");
    }

    try {
      this._itemrelation.set("item", this._item!);
      this._itemrelation.set("local_quantity", this._quantity!);

      await $heinzerling.sync.saveObject(this._itemrelation);

      message.success("Artikel wurde gespeichert.");

      runInAction(() => {
        this._open = false;
        this._itemrelation = null;
        this._item = null;
        this._quantity = null;

        this.orderstate.fetch();
      });
    } catch (error) {
      if (error instanceof Parse.Error) {
        message.error(
          "Speichern fehlgeschlagen: " + error.code + ": " + error.message
        );
      } else {
        message.error("Speichern fehlgeschlagen: Unbekannter Fehler");
      }
    }
    return null;
  }

  public async delete() {
    if (this._itemrelation) {
      this._itemrelation.set("local_quantity", 0);
      await $heinzerling.sync.saveObject(this._itemrelation);
    }
    runInAction(() => {
      this._open = false;
      this._itemrelation = null;
      this._item = null;
      this._quantity = null;

      this.orderstate.fetch();
    });
  }

  private async fetchItems() {
    if (this.items.length > 0) return;

    const items = await $heinzerling.sync
      .createQuery(Item)
      .limit(1000000)
      .find();

    runInAction(() => {
      this.items = items;
    });
  }
}
