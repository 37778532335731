import { message } from "antd";
import dayjs from "dayjs";
import { makeAutoObservable, runInAction } from "mobx";
import { ContactPerson, Order, OrderAttributes } from "../../../parse";
import { $heinzerling } from "../../../service";

type EmailSelectType = {
  label: string;
  value: string;
  key: string;
};

export class SignatureDrawerState {
  public loading: boolean = true;
  public sending: boolean = false;

  private ids: string[];
  public orders: Order[] = [];

  public customerSignature: string | undefined = undefined;
  public technicanSignature: string | undefined = undefined;

  public emails: EmailSelectType[] = [];

  public stepIndex: number = 0;
  public steps = [
    {
      key: "overview",
      label: "Auftrag abschließen",
    },
    {
      key: "customer",
      label: "Unterschrift Kunde",
    },
    {
      key: "technican",
      label: "Unterschrift Monteur",
    },
    {
      key: "finish",
      label: "Auftrag abschließen",
    },
  ];

  public emailregex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(ids: string[]) {
    this.ids = ids;

    makeAutoObservable(this);

    this.fetch();
  }

  get isLastStep() {
    return this.stepIndex === this.steps.length - 1;
  }

  get step() {
    return this.steps[this.stepIndex];
  }

  get disabled() {
    if (this.step.key === "customer") {
      return !this.customerSignature;
    }

    if (this.step.key === "technican") {
      return !this.technicanSignature;
    }

    return false;
  }

  next() {
    this.stepIndex = Math.min(
      this.steps.length - 1,
      Math.max(0, this.stepIndex + 1)
    );
  }

  setCustomerSignature(value: SignatureDrawerState["customerSignature"]) {
    this.customerSignature = value;
  }

  setTechnicanSignature(value: SignatureDrawerState["technicanSignature"]) {
    this.technicanSignature = value;
  }

  async fetch() {
    runInAction(() => {
      this.loading = true;
    });

    try {
      const orders = await Promise.all(
        this.ids.map((id) =>
          $heinzerling.sync
            .createQuery(Order)
            .include("customer")
            .include("facility")
            .get(id)
        )
      );

      let emails: Array<EmailSelectType> = [];
      const user = await $heinzerling.sync.getUser();
      if (user && user.getEmail()) {
        emails = [
          {
            value: user.getEmail()!,
            label: user.getEmail()!,
            key: user.getEmail()!,
          },
        ];

        //Add email for specific user
        //TODO: This should be deleted on prod
        if (user?.getEmail() === "j.wiederstein@heinzerling-aufzuege.de") {
          emails.push({
            value: "arbeitsberichte@heinzerling-aufzuege.de",
            label: "arbeitsberichte@heinzerling-aufzuege.de",
            key: "arbeitsberichte@heinzerling-aufzuege.de",
          });
        }
      }

      (
        await Promise.all(
          orders.map((order) =>
            $heinzerling.sync
              .createQuery(ContactPerson)
              .equalTo("customer", order.get("customer"))
              .find()
          )
        )
      )
        .flat()
        .map((contactperson) => {
          const cpemail = contactperson.get("email");
          if (cpemail) {
            emails.push({
              key: cpemail,
              value: cpemail,
              label: cpemail,
            });
          }
        });

      runInAction(() => {
        this.orders = orders;
        this.emails = emails;
        this.loading = false;
      });
    } catch (error) {
      $heinzerling.helper.handleError("query", error);

      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async save(data: Partial<OrderAttributes>) {
    this.sending = true;

    const hideMessage = message.loading("Auftrag wird abgeschlossen", 0);

    for (const order of this.orders) {
      order.set("local_signatureTimestamp", new Date());
      order.set("local_signatureCustomer", this.customerSignature);
      order.set("local_signatureTechnican", this.technicanSignature);

      for (let [key, value] of Object.entries(data)) {
        if (dayjs.isDayjs(value)) {
          value = value.toDate();
        }

        if (key === "local_mailto" && typeof value !== "undefined") {
          value = (value as string[]).join(",");
        }
        order.set(key as keyof OrderAttributes, value);
      }

      await $heinzerling.sync.saveObject(order);
    }

    $heinzerling.mobile.signOrders(null);
    hideMessage();
    message.success("Auftrag abgespeichert.");
  }
}
