import Parse from "parse";

import type { Customer } from "./Customer";

export interface FacilityAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  city: string;
  contractType?: string;
  customer?: Customer;
  keyDepot?: string;
  lastMaintenance?: Date;
  name: string;
  name2?: string;
  name3?: string;
  no: string;
  postcode: string;
  sqlCreatedAt?: Date;
  sqlDeleted: boolean;
  sqlRef: string;
  sqlUpdatedAt?: Date;
  street: string;
}

export class Facility extends Parse.Object<FacilityAttributes> {
  static className: string = "Heinzerling2_Facility";

  constructor(data?: Partial<FacilityAttributes>) {
    super("Heinzerling2_Facility", data as FacilityAttributes);
  }

  get city(): string {
    return super.get("city");
  }
  set city(value: string) {
    super.set("city", value);
  }
  get contractType(): string | undefined {
    return super.get("contractType");
  }
  set contractType(value: string | undefined) {
    super.set("contractType", value);
  }
  get customer(): Customer | undefined {
    return super.get("customer");
  }
  set customer(value: Customer | undefined) {
    super.set("customer", value);
  }
  get keyDepot(): string | undefined {
    return super.get("keyDepot");
  }
  set keyDepot(value: string | undefined) {
    super.set("keyDepot", value);
  }
  get lastMaintenance(): Date | undefined {
    return super.get("lastMaintenance");
  }
  set lastMaintenance(value: Date | undefined) {
    super.set("lastMaintenance", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get name2(): string | undefined {
    return super.get("name2");
  }
  set name2(value: string | undefined) {
    super.set("name2", value);
  }
  get name3(): string | undefined {
    return super.get("name3");
  }
  set name3(value: string | undefined) {
    super.set("name3", value);
  }
  get no(): string {
    return super.get("no");
  }
  set no(value: string) {
    super.set("no", value);
  }
  get postcode(): string {
    return super.get("postcode");
  }
  set postcode(value: string) {
    super.set("postcode", value);
  }
  get sqlCreatedAt(): Date | undefined {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value: Date | undefined) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted(): boolean {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value: boolean) {
    super.set("sqlDeleted", value);
  }
  get sqlRef(): string {
    return super.get("sqlRef");
  }
  set sqlRef(value: string) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt(): Date | undefined {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value: Date | undefined) {
    super.set("sqlUpdatedAt", value);
  }
  get street(): string {
    return super.get("street");
  }
  set street(value: string) {
    super.set("street", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_Facility", Facility);
