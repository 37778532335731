import Parse from "parse";

export interface OrderTypeAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  abbreviation: string;
  description?: string;
  label: string;
}

export class OrderType extends Parse.Object<OrderTypeAttributes> {
  static className: string = "Heinzerling2_OrderType";

  constructor(data?: Partial<OrderTypeAttributes>) {
    super("Heinzerling2_OrderType", data as OrderTypeAttributes);
  }

  get abbreviation(): string {
    return super.get("abbreviation");
  }
  set abbreviation(value: string) {
    super.set("abbreviation", value);
  }
  get description(): string | undefined {
    return super.get("description");
  }
  set description(value: string | undefined) {
    super.set("description", value);
  }
  get label(): string {
    return super.get("label");
  }
  set label(value: string) {
    super.set("label", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_OrderType", OrderType);
