import { Button, Drawer, Form, InputNumber, Select, Space } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { AddItemProps } from "..";

export default observer<AddItemProps>(({ state }) => {
  return (
    <Drawer
      title="Artikel hinzufügen"
      placement="bottom"
      open={!!state.open}
      onClose={() => (state.open = false)}
    >
      <Form layout="vertical">
        <Form.Item
          label="Artikel"
          validateStatus={state.item === null ? "error" : "success"}
          rules={[
            { required: true, message: "Bitte wähle einen Artikel aus." },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            placeholder="Wähle einen Artikel aus"
            options={state.itemOptions}
            value={state.item?.id}
            onChange={(value) => {
              state.item = value;
            }}
            onSearch={(searchString) => {
              state.optionsSearch = searchString || "";
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Menge"
          validateStatus={state.quantity === null ? "error" : "success"}
          rules={[{ required: true, message: "Bitte gib eine Menge an." }]}
        >
          <InputNumber
            value={state.quantity}
            decimalSeparator=","
            onChange={(value) => (state.quantity = value)}
            min={0}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              type="primary"
              children="Speichern"
              onClick={() => state.save()}
            />
            <Button children="Abbrechen" onClick={() => state.cancel()} />
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
});
