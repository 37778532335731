import * as indexdb from "idb-keyval";

export class ParseDataStore {
  public readonly async = 1;

  private __lock = new AsyncLock(true);
  private __writelock = new AsyncLock(false);
  private __store = new IDB("parseAlt", "parse");
  private __cache: Record<string, string> = {};
  private __dirty = false;

  constructor() {
    this.init();
  }

  private async init() {
    try {
      console.time("ParseDataStore.init()");
      this.__cache = JSON.parse((await this.__store.get("cache")) || "{}");
      console.timeEnd("ParseDataStore.init()");
    } catch (error) {
      console.error("ParseDataStore.init() Error:", error);
    } finally {
      this.__lock.unlock();
      this.write();
    }
  }

  private async write(once: boolean = false) {
    await this.__writelock.wait();

    if (this.__dirty) {
      console.time("ParseDataStore.write()");
      this.__writelock.lock();
      this.__dirty = false;
      const cache = JSON.stringify(this.__cache);
      await this.__store.set("cache", cache);
      this.__writelock.unlock();
      console.timeEnd("ParseDataStore.write()");
    }

    if (!once) {
      setTimeout(() => {
        this.write();
      }, 1000);
    }
  }

  private isImportantKey(key: string) {
    return key.startsWith("Parse/heinzerling/");
  }

  public async getItemAsync(path: string) {
    await this.__lock.wait();

    return this.__cache[path];
  }

  public async setItemAsync(path: string, value: string) {
    await this.__lock.wait();

    this.__cache[path] = value;
    this.__dirty = true;

    if (this.isImportantKey(path)) {
      await this.write(true);
    }
  }

  public async removeItemAsync(path: string) {
    await this.__lock.wait();

    delete this.__cache[path];
    this.__dirty = true;

    if (this.isImportantKey(path)) {
      await this.write(true);
    }
  }

  public async getAllKeysAsync() {
    await this.__lock.wait();

    return Object.keys(this.__cache);
  }

  public async clear() {
    await this.__lock.wait();

    this.__cache = {};

    await this.__store.clear();
  }
}

class AsyncLock {
  private promise: Promise<void> | undefined;
  private resolver: any = null;

  constructor(locked: boolean = false) {
    if (locked) {
      this.lock();
    }
  }

  lock() {
    this.promise = new Promise((resolve) => {
      this.resolver = resolve;
    });
  }

  unlock() {
    if (this.resolver) this.resolver();
  }

  wait() {
    if (this.promise) return this.promise;

    return Promise.resolve();
  }
}

class IDB {
  private store: indexdb.UseStore;

  constructor(dbName: string, storeName: string) {
    this.store = indexdb.createStore(dbName, storeName);
  }

  get(key: string) {
    return indexdb.get(key, this.store);
  }

  set(key: string, value: string) {
    return indexdb.set(key, value, this.store);
  }

  keys() {
    return indexdb.keys(this.store);
  }

  clear() {
    return indexdb.clear(this.store);
  }
}
