import React, { memo } from "react";

interface Props {
  text: string | undefined;
  fallback?: string;
}

export const DisplayText = memo<Props>(function DisplayText({
  text,
  fallback,
}) {
  if (!text) {
    if (fallback) {
      return <div>{fallback}</div>;
    } else {
      return null;
    }
  }

  text = text
    .split("\n\n")
    .map((t) => `<p>${t}</p>`)
    .join("");

  text = text.replaceAll("\n", "<br/>");

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
});
