import { useTranslation } from "@opendash/i18n";
import { Descriptions } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { formatAddress, formatDate } from "../helper/formatter";
import { Facility } from "../parse";

export default observer(({ facility }: { facility: Facility }) => {
  const t = useTranslation();

  return (
    <>
      <Descriptions
        bordered
        size="small"
        style={{ marginBottom: 30 }}
        column={1}
      >
        <Descriptions.Item
          label={t("app:classes.Facility.fields.no")}
          children={facility.no}
        />

        <Descriptions.Item
          label={t("app:classes.Facility.fields.contractType")}
          children={facility.contractType || "-"}
        />

        <Descriptions.Item
          label={t("app:classes.Facility.fields.keyDepot")}
          children={facility.keyDepot || "Keine Angabe"}
        />

        <Descriptions.Item
          label={t("app:classes.Facility.fields.address")}
          children={formatAddress(facility, true)}
        />

        <Descriptions.Item
          label={t("app:classes.Facility.fields.lastMaintenance")}
          children={formatDate(facility.lastMaintenance) || "-"}
        />
      </Descriptions>
    </>
  );
});
