import { Icon } from "@opendash/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  InputNumber,
  List,
  Select,
  Space,
} from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { UpdateTechnicianTabProps } from "..";
import { formatDate } from "../../../helper/formatter";
import { UpdateTechnicansState } from "../states/UpdateTechnicansState";

const HoursDiv = styled.span`
  width: 40px;
  // text-align: right;
  display: inline-block;

  margin-right: 10px;
`;

const IconsDiv = styled.span`
  margin: 0 10px;
  font-size: 14px;
`;

export default observer<UpdateTechnicianTabProps>(function UpdateTechnicansTab({
  state,
}) {
  if (!state.order) throw new Error("Invalid State");

  const updateTechnicianState = React.useMemo(
    () => new UpdateTechnicansState(state),
    []
  );

  return (
    <>
      <List
        dataSource={updateTechnicianState.list}
        renderItem={(tr) => {
          const title = tr.technican.name || "";

          const hours = (tr.local_hours ?? tr.hours) + " Std";

          return (
            <List.Item>
              <HoursDiv children={hours} />
              <List.Item.Meta
                title={title}
                description={formatDate(tr.start)}
              />

              <IconsDiv>
                {tr.outsideBusinessHours && (
                  <Icon
                    icon="fa:moon"
                    color="#cf942a"
                    title="Außerhalb der Geschäftszeiten"
                  />
                )}

                {tr.local_hideFromCustomer && (
                  <Icon
                    icon="fa:eye-slash"
                    color="#802acf"
                    title="Nicht für den Kunden sichtbar"
                  />
                )}
              </IconsDiv>
              <Button
                disabled={state.disabled}
                onClick={() => updateTechnicianState.setUpdateDialog(tr)}
              >
                <Icon icon="fa:pencil" />
              </Button>
            </List.Item>
          );
        }}
      />

      <IconsDiv>
        <div>
          <Icon icon="fa:moon" color="#cf942a" /> - Außerhalb der
          Geschäftszeiten
        </div>
        <div>
          <Icon icon="fa:eye-slash" color="#802acf" /> - Für den Kunden nicht
          sichtbar
        </div>
      </IconsDiv>

      <Divider />

      <Button
        type="primary"
        disabled={state.disabled}
        children="Neue Stunden hinzufügen"
        onClick={() => updateTechnicianState.create()}
      />

      <Drawer
        title="Monteur(in) bearbeiten"
        placement="bottom"
        open={!!updateTechnicianState.updateDialog}
        onClose={() => updateTechnicianState.setUpdateDialog(null)}
        height="560px"
      >
        <Form>
          <Form.Item
            label="Monteur"
            validateStatus={
              updateTechnicianState.updateDialogTechnican === null
                ? "error"
                : "success"
            }
          >
            <Select
              disabled={!!updateTechnicianState.updateDialog?.sqlRef}
              showSearch
              optionFilterProp="children"
              placeholder="Wähle einen Monteur aus"
              options={updateTechnicianState.technicanOptions}
              value={updateTechnicianState.updateDialogTechnican?.id}
              onChange={(value) => {
                updateTechnicianState.setUpdateDialogTechnican(value);
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            label="Datum"
            validateStatus={
              updateTechnicianState.updateDialogDate === null
                ? "error"
                : "success"
            }
          >
            <DatePicker
              disabled={!!updateTechnicianState.updateDialog?.sqlRef}
              value={
                updateTechnicianState.updateDialogDate
                  ? dayjs(updateTechnicianState.updateDialogDate)
                  : undefined
              }
              onChange={(value) => {
                updateTechnicianState.setUpdateDialogDate(
                  value?.toDate() || null
                );
              }}
            />
          </Form.Item>
          <Form.Item
            label="Stunden"
            validateStatus={
              updateTechnicianState.updateDialogHours === null
                ? "error"
                : "success"
            }
          >
            <InputNumber
              autoFocus
              value={updateTechnicianState.updateDialogHours}
              decimalSeparator=","
              onChange={(value) => {
                updateTechnicianState.setUpdateDialogHours(value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Sonstiges"
            validateStatus={
              updateTechnicianState.updateDialogOutsideBusinessHours === null
                ? "error"
                : "success"
            }
          >
            <Checkbox
              checked={updateTechnicianState.updateDialogOutsideBusinessHours}
              onChange={(e) => {
                updateTechnicianState.setUpdateDialogOutsideBusinessHours(
                  e.target.checked
                );
              }}
              children="Die Arbeiten fanden außerhalb der Geschäftszeiten statt"
            />

            <br />

            <Checkbox
              checked={updateTechnicianState.updateDialogHideFromCustomer}
              onChange={(e) => {
                updateTechnicianState.setUpdateDialogHideFromCustomer(
                  e.target.checked
                );
              }}
              children="Für den Kunden nicht sichtbar"
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                type="primary"
                children="Speichern"
                onClick={() => {
                  updateTechnicianState.saveUpdateDialog();
                }}
              />
              <Button
                danger
                type="primary"
                children="Löschen"
                onClick={() => {
                  updateTechnicianState.destoryUpdateDialog();
                }}
              />
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
});
