import { $framework, AdminLayout, useTranslation } from "@opendash/core";
import { ParseObject } from "@opendash/plugin-parse";
import { AdminToolbar } from "@opendash/ui";
import { Button, Divider } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorMessage } from "../../components/ErrorMessage";
import { OrderMedia, PrintView } from "../../features/orders";
import { Order } from "../../parse";
import { $heinzerling } from "../../service";

export const OrderDetailPage = observer(() => {
  const t = useTranslation();
  const { orderId } = useParams();
  const [order, setOrder] = useState<Order>();

  useEffect(() => {
    async function getOrder() {
      return await $heinzerling.sync
        .createQuery(Order)
        .include("customer")
        .include("facility")
        .get(orderId as string);
    }
    getOrder().then(setOrder);
  }, []);

  if (!order) {
    return (
      <ErrorMessage
        icon="fa:exclamation-circle"
        title="Facility nicht gefunden"
        message="Unter dieser URL konnte kein Facility gefunden werden."
      />
    );
  }

  const pdf = order.get("sys_pdf")?.url();
  return (
    <AdminLayout contentPadding>
      <AdminToolbar
        title={t("app:string.title_auftrag") + " " + order.get("no")}
        actions={[
          <Button
            key="toorder"
            children={t("app:orders.action_goto_app")}
            onClick={() =>
              $framework.router.navigate(
                `/phone/order/${orderId}/view-overview`
              )
            }
          />,
        ]}
      />
      <ParseObject
        object={order}
        fields={[
          "no",
          "date",
          "type",
          "local_documentText",
          "local_finishedText",
        ]}
        editFields={["local_documentText", "local_finishedText"]}
        disableDelete
      />

      <OrderMedia order={order} />

      <Divider />

      {pdf && (
        <PrintView
          title={t("app:orders.workreport.title")}
          preview={pdf}
          download={pdf}
        />
      )}

      {!pdf && (
        <PrintView
          title={t("app:orders.workreport.title_preview")}
          preview={`https://heinzerling-print-service-dev.apps.openinc.dev/render/auftragsbestaetigung/${orderId}`}
          download={`https://heinzerling-print-service-dev.apps.openinc.dev/print/auftragsbestaetigung/${orderId}`}
        />
      )}
    </AdminLayout>
  );
});
