import { makeAutoObservable } from "mobx";
import { JobQueueType } from "..";

/**
 * @author Nico Vitt
 *
 * This service helps storing Parse objects when being off line.
 */
class OfflineService {
  private isOnline: boolean = window.navigator.onLine;
  private changeHandlers: Array<Function> = new Array();
  public jobQueue: Array<JobQueueType> = new Array();

  constructor() {
    makeAutoObservable(this);
    this.isOnline = window.navigator.onLine;

    window.addEventListener("online", () => this.listenerOnline());
    window.addEventListener("offline", () => this.listenerOffline());
  }

  public get online(): boolean {
    return this.isOnline;
  }

  private listenerOnline() {
    this.isOnline = true;
    this.callChangeHandler();

    // Sync queue
    this.jobQueue.forEach(async (value: JobQueueType) => {
      console.log("Running queue for object", value.object.id);
      if (value.action === "create") {
        await value.object.save();
      }
      if (value.action === "update") {
        await value.object.save();
      }
      if (value.action === "delete") {
        await value.object.destroy();
      }
    });
  }

  private listenerOffline() {
    this.isOnline = false;
    this.callChangeHandler();
  }

  private callChangeHandler() {
    this.changeHandlers.forEach((changeHandler) =>
      changeHandler(this.isOnline)
    );
  }

  public addChangeHandler(handler: Function | undefined) {
    if (handler) {
      this.changeHandlers.push(handler);
    }
  }
}

export const $offlineService = new OfflineService();
