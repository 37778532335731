import { observer } from "mobx-react-lite";
import React from "react";
import {
  ContactPersonTab,
  NavigationTab,
  OverviewTab,
  UpdateItemsTab,
  UpdatePhotosTab,
  UpdateTechnicansTab,
  UpdateTextsTab,
} from "..";
import { OrderState } from "../../orders";
import HistoryTab from "./HistoryTab";

export default observer<{ state: OrderState }>(function TabSwitch({ state }) {
  switch (state.tab) {
    case "view-navigation":
      return <NavigationTab state={state} />;
    case "view-contacts":
      return <ContactPersonTab state={state} />;
    case "view-history":
      return <HistoryTab state={state} />;
    case "update-technicans":
      return <UpdateTechnicansTab state={state} />;
    case "update-items":
      return <UpdateItemsTab orderstate={state} />;
    case "update-photos":
      return <UpdatePhotosTab state={state} />;
    case "update-texts":
      return <UpdateTextsTab state={state} />;
    case "view-overview":
    default:
      return <OverviewTab state={state} />;
  }
});
