import { makeAutoObservable } from "mobx";
import { OrderState } from "../../orders/states/OrderState";

export class TabState {
  private state: OrderState;

  constructor(state: OrderState) {
    this.state = state;

    makeAutoObservable(this);
  }

  get list() {
    return this.state.items.filter(
      (i) => i.position !== undefined && !i.local_deleted
    );
  }

  //   public addItem(item?: Item) {
  //     if (!this.state.order) throw new Error("Invalid State");
  //     const parent = this.showPartsList;
  //     const position = this.calculatePosition(parent);
  //     const parentPosition = parent ? parent.position : undefined;
  //     const relation = new OrderItemRelation({
  //       order: this.state.order,
  //       quantity: 1,
  //       position,
  //       parentPosition,
  //       item,
  //     });
  //     runInAction(() => {
  //       this.state.items.push(relation);
  //     });
  //     this.setUpdateDialog(relation);
  //   }
}
