import { Icon } from "@opendash/icons";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { OrderState } from "../../orders";

const TabLabelContainer = styled.div`
  text-align: center;
  // padding: 0 4px;
  // width: 20vw;
  // max-width: 200px;

  .anticon {
    margin-right: 0 !important;
    font-size: 20px;
  }

  .tablabel {
    font-size: 10px;
  }
`;

export default observer<{ menuitem: OrderState["menu"][0] }>(function TabLabel({
  menuitem: menuitem,
}) {
  return (
    <TabLabelContainer>
      <Icon icon={menuitem.iconKey} title={menuitem.label} />
      <div className="tablabel">{menuitem.label}</div>
    </TabLabelContainer>
  );
});
