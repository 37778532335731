import { makeAutoObservable, runInAction } from "mobx";
import { calculatePosition } from "..";
import { Item, OrderItemRelation } from "../../../parse";
import { $heinzerling } from "../../../service";
import { OrderState } from "../../orders/states/OrderState";

export class AddItemState {
  private orderstate: OrderState;
  private items: Item[] = [];
  private _open: boolean = false;
  private _partsList: OrderItemRelation | null = null;
  // private _itemrelation: OrderItemRelation | null = null;
  private _item: Item | null = null;
  private _quantity: number | null = null;
  private _optionsSearch = "";

  constructor(orderstate: OrderState) {
    this.orderstate = orderstate;
    this.fetchItems();
    makeAutoObservable(this);
  }

  public get open(): boolean {
    return this._open;
  }

  public set open(v: boolean) {
    this._open = v;
  }

  public get partsList(): AddItemState["_partsList"] {
    return this._partsList;
  }

  public set partsList(v: AddItemState["_partsList"]) {
    this._partsList = v;
  }

  public get item(): AddItemState["_item"] {
    return this._item;
  }

  public set item(v: AddItemState["_item"] | string) {
    if (typeof v === "string") {
      const item = this.items.find((i) => i.id === v) || null;

      this._item = item;
    } else {
      this._item = v;
    }
  }

  get itemOptions() {
    let items = this.items;

    if (this._optionsSearch === "") {
      items = items.filter((item) => item.inAutofill);
    }

    return this.items.map((item) => ({
      value: item.id,
      label: `[${item.no}] ${item.name}`,
    }));
  }

  public get optionsSearch(): AddItemState["_optionsSearch"] {
    return this._optionsSearch;
  }

  public set optionsSearch(v: AddItemState["_optionsSearch"]) {
    this._optionsSearch = v;
  }

  public get quantity(): AddItemState["_quantity"] {
    return this._quantity;
  }

  public set quantity(v: AddItemState["_quantity"]) {
    this._quantity = v;
  }

  // public get itemrelation(): AddItemState["_itemrelation"] {
  //   return this._itemrelation;
  // }

  // public set itemrelation(v: AddItemState["_itemrelation"]) {
  //   this._itemrelation = v;

  //   this._item = v?.item || null;
  //   this._quantity = v?.local_quantity ?? v?.quantity ?? 0;

  //   this.fetchItems();
  // }

  public async save() {
    const parent = this._partsList;

    const position = calculatePosition(parent, this.orderstate);

    const parentPosition = parent ? parent.position : undefined;

    if (this._item) {
      const relation = new OrderItemRelation({
        order: this.orderstate.order!,
        local_quantity: this._quantity ? this._quantity : 0,
        position,
        parentPosition,
        item: this._item,
      });

      await runInAction(async () => {
        await $heinzerling.sync.saveObject(relation);
        this.orderstate.items.push(relation);
      });

      this.resetDrawer();
    }
  }

  public cancel() {
    this.resetDrawer();
  }

  private resetDrawer() {
    this._item = null;
    this._quantity = null;
    this._optionsSearch = "";
    this._partsList = null;
    this._open = false;
  }

  private async fetchItems() {
    if (this.items.length > 0) return;

    const items = await $heinzerling.sync
      .createQuery(Item)
      .limit(1000000)
      .find();

    runInAction(() => {
      this.items = items;
    });
  }
}
