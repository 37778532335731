import Parse from "parse";

export interface ItemAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  description4: string;
  inAutofill?: boolean;
  inPlanView: boolean;
  name: string;
  no: string;
  resource?: boolean;
  searchIndex: string;
  sqlCreatedAt?: Date;
  sqlDeleted: boolean;
  sqlRef?: string;
  sqlUpdatedAt?: Date;
  unit: string;
}

export class Item extends Parse.Object<ItemAttributes> {
  static className: string = "Heinzerling2_Item";

  constructor(data?: Partial<ItemAttributes>) {
    super("Heinzerling2_Item", data as ItemAttributes);
  }

  get description4(): string {
    return super.get("description4");
  }
  set description4(value: string) {
    super.set("description4", value);
  }
  get inAutofill(): boolean | undefined {
    return super.get("inAutofill");
  }
  set inAutofill(value: boolean | undefined) {
    super.set("inAutofill", value);
  }
  get inPlanView(): boolean {
    return super.get("inPlanView");
  }
  set inPlanView(value: boolean) {
    super.set("inPlanView", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get no(): string {
    return super.get("no");
  }
  set no(value: string) {
    super.set("no", value);
  }
  get resource(): boolean | undefined {
    return super.get("resource");
  }
  set resource(value: boolean | undefined) {
    super.set("resource", value);
  }
  get searchIndex(): string {
    return super.get("searchIndex");
  }
  set searchIndex(value: string) {
    super.set("searchIndex", value);
  }
  get sqlCreatedAt(): Date | undefined {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value: Date | undefined) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted(): boolean {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value: boolean) {
    super.set("sqlDeleted", value);
  }
  get sqlRef(): string | undefined {
    return super.get("sqlRef");
  }
  set sqlRef(value: string | undefined) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt(): Date | undefined {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value: Date | undefined) {
    super.set("sqlUpdatedAt", value);
  }
  get unit(): string {
    return super.get("unit");
  }
  set unit(value: string) {
    super.set("unit", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_Item", Item);
