import Parse from "parse";

export interface CustomerAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  city: string;
  name: string;
  name2: string;
  name3: string;
  no: string;
  postcode: string;
  sqlCreatedAt?: Date;
  sqlDeleted: boolean;
  sqlRef: string;
  sqlUpdatedAt?: Date;
  street: string;
}

export class Customer extends Parse.Object<CustomerAttributes> {
  static className: string = "Heinzerling2_Customer";

  constructor(data?: Partial<CustomerAttributes>) {
    super("Heinzerling2_Customer", data as CustomerAttributes);
  }

  get city(): string {
    return super.get("city");
  }
  set city(value: string) {
    super.set("city", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get name2(): string {
    return super.get("name2");
  }
  set name2(value: string) {
    super.set("name2", value);
  }
  get name3(): string {
    return super.get("name3");
  }
  set name3(value: string) {
    super.set("name3", value);
  }
  get no(): string {
    return super.get("no");
  }
  set no(value: string) {
    super.set("no", value);
  }
  get postcode(): string {
    return super.get("postcode");
  }
  set postcode(value: string) {
    super.set("postcode", value);
  }
  get sqlCreatedAt(): Date | undefined {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value: Date | undefined) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted(): boolean {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value: boolean) {
    super.set("sqlDeleted", value);
  }
  get sqlRef(): string {
    return super.get("sqlRef");
  }
  set sqlRef(value: string) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt(): Date | undefined {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value: Date | undefined) {
    super.set("sqlUpdatedAt", value);
  }
  get street(): string {
    return super.get("street");
  }
  set street(value: string) {
    super.set("street", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_Customer", Customer);
