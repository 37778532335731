import { observer } from "mobx-react-lite";
import React from "react";
import { SignatureDrawer } from "../../features/signature";
import { $heinzerling } from "../../service";

export const PhoneGlobal = observer(() => {
  if (!$heinzerling.mobile.signIds) {
    return null;
  }

  return <SignatureDrawer />;
});
