import { useTranslation } from "@opendash/i18n";
import { Descriptions, Divider, Table } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { DisplayText } from "../../../components/DisplayText";
import FacilityDescription from "../../../components/FacilityDescription";
import {
  formatAddress,
  formatDate,
  formatName,
} from "../../../helper/formatter";
import { OverviewTabProps } from "../types/OverviewTabProps";

export default observer<OverviewTabProps>(function OverviewTab({ state }) {
  const t = useTranslation();

  if (!state.order) return null;

  return (
    <>
      <Descriptions
        title="Auftragsinformationen"
        bordered
        size="small"
        style={{ marginBottom: 30 }}
        column={1}
      >
        <Descriptions.Item label="Art" children={state.order.type} />
        <Descriptions.Item
          label="Datum"
          children={formatDate(state.order.date)}
        />
        <Descriptions.Item
          label="Kunde"
          children={formatName(state.order.customer)}
        />
        <Descriptions.Item
          label="Kundenanschrift"
          children={formatAddress(state.order.customer, true)}
        />
        <Descriptions.Item
          label={t("app:classes.Order.fields.referenceContact")}
          children={state.order.referenceContact || "-"}
        />
        <Descriptions.Item
          label={t("app:classes.Order.fields.referenceNo")}
          children={state.order.referenceNo || "-"}
        />
        <Descriptions.Item
          label={t("app:classes.Order.fields.referenceDate")}
          children={formatDate(state.order.referenceDate) || "-"}
        />
      </Descriptions>

      {state.rel ? (
        <>
          <div
            className="ant-descriptions-title"
            children={`${t(
              "app:classes.Order.fields.relationdescription"
            )} ${new Date(state.order.get("date")!).toLocaleDateString(
              "de-de"
            )} um ${new Date(state.order.get("date")!).toLocaleString("de-de", {
              hour: "2-digit",
              minute: "2-digit",
            })}`}
          />

          <DisplayText text={state.relationdescription} fallback="-" />
        </>
      ) : null}

      <div
        className="ant-descriptions-title"
        children={t("app:classes.Order.fields.documentText")}
      />

      <DisplayText text={state.order.documentText} fallback="-" />

      <div
        className="ant-descriptions-title"
        children={t("app:classes.Order.fields.orderText")}
      />

      <DisplayText text={state.order.orderText} fallback="-" />

      {/* <div
        className="ant-descriptions-title"
        children={t("app:classes.Order.fields.finishedText")}
      />

      <DisplayText text={state.order.finishedText} fallback="-" /> */}

      <div
        className="ant-descriptions-title"
        children={t("app:classes.Order.fields.introductionText")}
      />

      <DisplayText text={state.order.introductionText} fallback="-" />

      {/* <div
        className="ant-descriptions-title"
        children={t("app:classes.Order.fields.closingText")}
      />

      <DisplayText text={state.order.closingText} fallback="-" /> */}

      <Divider children={state.facilities.length > 1 ? "Aufzüge" : "Aufzug"} />

      {state.facilities.map((f) => (
        <FacilityDescription key={f.id} facility={f} />
      ))}

      <Divider children="Material" />

      <Table
        dataSource={state.getItemsSummary()}
        size="small"
        pagination={false}
        columns={[
          {
            key: "position",
            dataIndex: "position",
            title: "Pos.",
          },
          {
            key: "name",
            dataIndex: "name",
            title: "Name",
          },
          {
            key: "quantity",
            dataIndex: "quantity",
            title: "Menge",
          },
          {
            key: "unit",
            dataIndex: "unit",
            title: "Einheit",
          },
        ]}
      />

      <Divider children="Arbeiten" />

      <Table
        dataSource={state.getTechnicansSummary()}
        size="small"
        pagination={false}
        columns={[
          {
            key: "name",
            dataIndex: "name",
            title: "Name",
          },
          {
            key: "date",
            dataIndex: "date",
            title: "Datum",
          },
          {
            key: "hours",
            dataIndex: "hours",
            title: "Stunden",
          },
          {
            key: "other",
            dataIndex: "other",
            title: "",
          },
        ]}
      />
    </>
  );
});
