import { $parse } from "@opendash/plugin-parse";
import { makeAutoObservable } from "mobx";
import type { HeinzerlingService } from ".";
import { Order } from "../parse";

export class HeinzerlingMobileService {
  public signIds: string[] | null = null;

  constructor(app: HeinzerlingService) {
    makeAutoObservable(this);
  }

  signOrders(ids: HeinzerlingMobileService["signIds"]) {
    this.signIds = ids;
  }

  isOrderReadOnly(order?: Order | null) {
    if (!order) {
      return true;
    }

    if (
      order.local_signatureTimestamp ||
      order.local_signatureCustomer ||
      order.local_signatureTechnican
    ) {
      return true;
    }

    return !$parse.ui.getObjectPermission(order, "update");
  }
}
