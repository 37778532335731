import { AdminLayout, useTranslation } from "@opendash/core";
import { ParseObject } from "@opendash/plugin-parse";
import { Divider } from "antd";
import Parse from "parse";
import { useParseGet, useParseQuery } from "parse-hooks";
import React, { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { Facility, Order } from "../../../parse";
import { OrdersList } from "../../orders";

export default memo(function ElevatorsViewRoute() {
  const t = useTranslation();
  const navigate = useNavigate();

  const { elevatorId } = useParams();

  const elevator = useParseGet(Facility, elevatorId as string, {
    includes: ["customer"],
  });

  const orderQuery = React.useMemo(
    () =>
      new Parse.Query(Order)
        .equalTo("facility", elevator.result!)
        .exists("date")
        .exists("customer")
        .descending("date")
        .include("customer")
        .include("facility")
        .limit(1000000),
    [elevator.result?.id]
  );

  const orders = useParseQuery(orderQuery);

  if (!elevator.result || elevator.error) {
    return (
      <ErrorMessage
        icon="fa:exclamation-circle"
        title="Aufzug nicht gefunden"
        message="Unter dieser URL konnte kein Aufzug gefunden werden."
      />
    );
  }

  return (
    <AdminLayout contentPadding>
      <ParseObject
        object={elevator.result}
        fields={["no", "street", "postcode", "city"]}
        editFields={["no", "street", "postcode", "city"]}
      />
      <Divider />

      <OrdersList
        rows={orders.result}
        loading={orders.loading}
        hideElevatorLink
      />
    </AdminLayout>
  );
});
