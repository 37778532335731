import { Icon } from "@opendash/icons";
import { Button, Space } from "antd";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import styled from "styled-components";
import {
  PartsListDrawer,
  PartsListEntryProps,
  PartsListState,
  UpdateItemDrawer,
  UpdateItemState,
} from "..";

const Quantity = styled.span`
  width: 40px;
  display: inline-block;
`;

export default observer<PartsListEntryProps>(({ oir, orderstate }) => {
  const partsliststate = useMemo(() => new PartsListState(oir, orderstate), []);
  const updateitemstate = useMemo(() => new UpdateItemState(orderstate), []);

  let title = "";

  if (typeof oir.item === "undefined") {
    title = oir.description;
  }

  if (typeof oir.item !== "undefined" && title.length === 0) {
    title = oir.item.name || oir.item.description4;
  }

  // If this is a dummy then display the description from the order
  if (title.toLowerCase() === "dummy") {
    title = oir.description;
  }

  const quantity =
    (updateitemstate.quantity ?? oir.local_quantity ?? oir.quantity) +
    " " +
    oir.item?.unit;
  return (
    <>
      <Space>
        <Quantity children={quantity} />
        <span>{title}</span>
      </Space>

      <div>
        <Space>
          {partsliststate.showPartsList(oir) ? (
            <Button
              onClick={() => {
                partsliststate.name = title;
                partsliststate.open = true;
              }}
            >
              <Icon icon="fa:list-ol" />
            </Button>
          ) : null}
          {oir.item || null ? (
            <Button
              disabled={orderstate.disabled}
              onClick={() => {
                updateitemstate.itemrelation = oir;
                updateitemstate.open = true;
              }}
            >
              <Icon icon="fa:pencil" />
            </Button>
          ) : null}
        </Space>
      </div>
      <PartsListDrawer state={partsliststate} />

      <UpdateItemDrawer state={updateitemstate} />
    </>
  );
});
