import Parse from "parse";

import type { Facility } from "./Facility";
import type { Order } from "./Order";
import type { Technican } from "./Technican";

export interface OrderTechnicanRelationAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  category: number;
  description?: string;
  end?: Date;
  facility?: Facility;
  fromSync: boolean;
  hours: number;
  local_deleted: boolean;
  local_hideFromCustomer: boolean;
  local_hours?: number;
  order?: Order;
  outsideBusinessHours: boolean;
  sqlCreatedAt?: Date;
  sqlDeleted: boolean;
  sqlRef?: string;
  sqlUpdatedAt?: Date;
  start: Date;
  sys_app_id: number;
  technican: Technican;
}

export class OrderTechnicanRelation extends Parse.Object<OrderTechnicanRelationAttributes> {
  static className: string = "Heinzerling2_OrderTechnicanRelation";

  constructor(data?: Partial<OrderTechnicanRelationAttributes>) {
    super("Heinzerling2_OrderTechnicanRelation", data as OrderTechnicanRelationAttributes);
  }

  get category(): number {
    return super.get("category");
  }
  set category(value: number) {
    super.set("category", value);
  }
  get description(): string | undefined {
    return super.get("description");
  }
  set description(value: string | undefined) {
    super.set("description", value);
  }
  get end(): Date | undefined {
    return super.get("end");
  }
  set end(value: Date | undefined) {
    super.set("end", value);
  }
  get facility(): Facility | undefined {
    return super.get("facility");
  }
  set facility(value: Facility | undefined) {
    super.set("facility", value);
  }
  get fromSync(): boolean {
    return super.get("fromSync");
  }
  set fromSync(value: boolean) {
    super.set("fromSync", value);
  }
  get hours(): number {
    return super.get("hours");
  }
  set hours(value: number) {
    super.set("hours", value);
  }
  get local_deleted(): boolean {
    return super.get("local_deleted");
  }
  set local_deleted(value: boolean) {
    super.set("local_deleted", value);
  }
  get local_hideFromCustomer(): boolean {
    return super.get("local_hideFromCustomer");
  }
  set local_hideFromCustomer(value: boolean) {
    super.set("local_hideFromCustomer", value);
  }
  get local_hours(): number | undefined {
    return super.get("local_hours");
  }
  set local_hours(value: number | undefined) {
    super.set("local_hours", value);
  }
  get order(): Order | undefined {
    return super.get("order");
  }
  set order(value: Order | undefined) {
    super.set("order", value);
  }
  get outsideBusinessHours(): boolean {
    return super.get("outsideBusinessHours");
  }
  set outsideBusinessHours(value: boolean) {
    super.set("outsideBusinessHours", value);
  }
  get sqlCreatedAt(): Date | undefined {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value: Date | undefined) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted(): boolean {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value: boolean) {
    super.set("sqlDeleted", value);
  }
  get sqlRef(): string | undefined {
    return super.get("sqlRef");
  }
  set sqlRef(value: string | undefined) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt(): Date | undefined {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value: Date | undefined) {
    super.set("sqlUpdatedAt", value);
  }
  get start(): Date {
    return super.get("start");
  }
  set start(value: Date) {
    super.set("start", value);
  }
  get sys_app_id(): number {
    return super.get("sys_app_id");
  }
  set sys_app_id(value: number) {
    super.set("sys_app_id", value);
  }
  get technican(): Technican {
    return super.get("technican");
  }
  set technican(value: Technican) {
    super.set("technican", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_OrderTechnicanRelation", OrderTechnicanRelation);
