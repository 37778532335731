import { Icon } from "@opendash/icons";
import { Button, List } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { ContactPersonTabProps } from "..";

export default observer<ContactPersonTabProps>(function ContactPersonTab({
  state,
}) {
  const contacts = React.useMemo(
    () =>
      state.contacts.flatMap((contact) => {
        return [contact.phone, contact.mobile].filter(Boolean).map((nr) => {
          return {
            key: contact.id + "---" + nr,
            nr,
            title: contact.name,
            desciption: nr,
          };
        });
      }),
    [state.contacts]
  );

  return (
    <List
      dataSource={contacts}
      renderItem={(contact) => (
        <List.Item
          actions={[
            <Button
              onClick={() => {
                window.open(`tel:${contact.nr}`);
              }}
            >
              <Icon icon="fa:phone" />
            </Button>,
          ]}
        >
          <List.Item.Meta
            title={contact.title}
            description={contact.desciption}
          />
        </List.Item>
      )}
    />
  );
});
