import { message } from "antd";
import { makeAutoObservable } from "mobx";
import { Item, ItemAttributes } from "../../../parse";
import { $heinzerling } from "../../../service";

export class CreateItemState {
  private _open: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  public get open(): boolean {
    return this._open;
  }

  public set open(v: boolean) {
    this._open = v;
  }

  public async save(
    data: Pick<ItemAttributes, "name" | "unit" | "description4">
  ) {
    try {
      data.unit ||= "?";

      const item = new Item({
        ...data,
        no: "APP-ITEM",
      });

      await $heinzerling.sync.saveObject(item);

      message.success("Artikel wurde gespeichert.");

      //   this.addItem(item);
    } catch (error) {
      if (error instanceof Parse.Error) {
        message.error(
          "Speichern fehlgeschlagen: " + error.code + ": " + error.message
        );
      } else {
        message.error("Speichern fehlgeschlagen: Unbekannter Fehler");
      }
    }
  }
}
