import { Icon } from "@opendash/icons";
import { Button, Card, Descriptions, Tag } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router";
import { formatDateTime } from "../../../helper/formatter";
import { OrderTechnicanRelation } from "../../../parse";
import { $heinzerling } from "../../../service";

interface Props {
  ot: OrderTechnicanRelation;
}

export default observer<Props>(({ ot }) => {
  const navigate = useNavigate();

  const readOnly = $heinzerling.mobile.isOrderReadOnly(ot.order!);

  const customer = ot.order?.customer;

  const title = ot.order?.no + " " + customer?.name;
  const metaTitle = undefined;
  const metaDescription = formatDateTime(ot.start);
  const type = ot.get("order")?.get("type") || "Unbekannt";

  const details: Record<string, string> = {
    Auftragsnummer: ot.order?.no || "-",
    Ansprechpartner: ot.order?.referenceContact || "-",
  };

  return (
    <>
      <Card
        title={title}
        style={{ marginBottom: 20 }}
        extra={getTypeTag(type)}
        actions={[
          <Button
            type="text"
            onClick={() => {
              navigate(`/phone/order/${ot.get("order")!.id}`);
            }}
          >
            <Icon icon="fa:eye" />
          </Button>,
          <Button
            disabled={readOnly}
            type="text"
            onClick={() => {
              $heinzerling.mobile.signOrders([ot.get("order")!.id]);
            }}
          >
            <Icon icon="fa:signature" />
          </Button>,
        ]}
      >
        <Card.Meta
          title={metaTitle}
          description={metaDescription}
          style={{ marginBottom: 20 }}
        />

        <Descriptions size="small" column={1}>
          {Object.entries(details).map(([label, children], i) => (
            <Descriptions.Item key={i} label={label} children={children} />
          ))}
        </Descriptions>
      </Card>
    </>
  );
});

function getTypeTag(type: string) {
  switch (type) {
    case "STÖRUNG":
      return <Tag color="error">{type}</Tag>;

    case "WART":
      return <Tag color="warning">{type}</Tag>;

    case "TÜV":
      return <Tag color="processing">{type}</Tag>;

    case "SERVICE":
      return <Tag color="success">{type}</Tag>;

    default:
      return <Tag>{type}</Tag>;
  }
}
