import Parse from "parse";

import type { Customer } from "./Customer";
import type { Facility } from "./Facility";
import type { _User } from "./_User";

export interface OrderAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  closingText?: string;
  customer?: Customer;
  date?: Date;
  documentText?: string;
  facility: Facility;
  finishedText: string;
  introductionText?: string;
  isMaintenance: boolean;
  isNewOrder: boolean;
  local_createdOnMobile: boolean;
  local_documentText?: string;
  local_emergencyService: boolean;
  local_finishedText?: string;
  local_mailto?: string;
  local_moreWorkNecessary?: boolean;
  local_overvoltageDamage: boolean;
  local_signatureCustomer?: string;
  local_signatureTechnican?: string;
  local_signatureTimestamp?: Date;
  local_vandalism: boolean;
  no: string;
  orderText?: string;
  referenceContact?: string;
  referenceDate?: Date;
  referenceNo?: string;
  releaseForInvoice: boolean;
  sqlCreatedAt?: Date;
  sqlDeleted: boolean;
  sqlRef?: string;
  sqlUpdatedAt?: Date;
  sys_locked: boolean;
  sys_mailDelivered?: any;
  sys_mailSent: boolean;
  sys_pdf?: Parse.File;
  type?: string;
  user?: _User;
}

export class Order extends Parse.Object<OrderAttributes> {
  static className: string = "Heinzerling2_Order";

  constructor(data?: Partial<OrderAttributes>) {
    super("Heinzerling2_Order", data as OrderAttributes);
  }

  get closingText(): string | undefined {
    return super.get("closingText");
  }
  set closingText(value: string | undefined) {
    super.set("closingText", value);
  }
  get customer(): Customer | undefined {
    return super.get("customer");
  }
  set customer(value: Customer | undefined) {
    super.set("customer", value);
  }
  get date(): Date | undefined {
    return super.get("date");
  }
  set date(value: Date | undefined) {
    super.set("date", value);
  }
  get documentText(): string | undefined {
    return super.get("documentText");
  }
  set documentText(value: string | undefined) {
    super.set("documentText", value);
  }
  get facility(): Facility {
    return super.get("facility");
  }
  set facility(value: Facility) {
    super.set("facility", value);
  }
  get finishedText(): string {
    return super.get("finishedText");
  }
  set finishedText(value: string) {
    super.set("finishedText", value);
  }
  get introductionText(): string | undefined {
    return super.get("introductionText");
  }
  set introductionText(value: string | undefined) {
    super.set("introductionText", value);
  }
  get isMaintenance(): boolean {
    return super.get("isMaintenance");
  }
  set isMaintenance(value: boolean) {
    super.set("isMaintenance", value);
  }
  get isNewOrder(): boolean {
    return super.get("isNewOrder");
  }
  set isNewOrder(value: boolean) {
    super.set("isNewOrder", value);
  }
  get local_createdOnMobile(): boolean {
    return super.get("local_createdOnMobile");
  }
  set local_createdOnMobile(value: boolean) {
    super.set("local_createdOnMobile", value);
  }
  get local_documentText(): string | undefined {
    return super.get("local_documentText");
  }
  set local_documentText(value: string | undefined) {
    super.set("local_documentText", value);
  }
  get local_emergencyService(): boolean {
    return super.get("local_emergencyService");
  }
  set local_emergencyService(value: boolean) {
    super.set("local_emergencyService", value);
  }
  get local_finishedText(): string | undefined {
    return super.get("local_finishedText");
  }
  set local_finishedText(value: string | undefined) {
    super.set("local_finishedText", value);
  }
  get local_mailto(): string | undefined {
    return super.get("local_mailto");
  }
  set local_mailto(value: string | undefined) {
    super.set("local_mailto", value);
  }
  get local_moreWorkNecessary(): boolean | undefined {
    return super.get("local_moreWorkNecessary");
  }
  set local_moreWorkNecessary(value: boolean | undefined) {
    super.set("local_moreWorkNecessary", value);
  }
  get local_overvoltageDamage(): boolean {
    return super.get("local_overvoltageDamage");
  }
  set local_overvoltageDamage(value: boolean) {
    super.set("local_overvoltageDamage", value);
  }
  get local_signatureCustomer(): string | undefined {
    return super.get("local_signatureCustomer");
  }
  set local_signatureCustomer(value: string | undefined) {
    super.set("local_signatureCustomer", value);
  }
  get local_signatureTechnican(): string | undefined {
    return super.get("local_signatureTechnican");
  }
  set local_signatureTechnican(value: string | undefined) {
    super.set("local_signatureTechnican", value);
  }
  get local_signatureTimestamp(): Date | undefined {
    return super.get("local_signatureTimestamp");
  }
  set local_signatureTimestamp(value: Date | undefined) {
    super.set("local_signatureTimestamp", value);
  }
  get local_vandalism(): boolean {
    return super.get("local_vandalism");
  }
  set local_vandalism(value: boolean) {
    super.set("local_vandalism", value);
  }
  get no(): string {
    return super.get("no");
  }
  set no(value: string) {
    super.set("no", value);
  }
  get orderText(): string | undefined {
    return super.get("orderText");
  }
  set orderText(value: string | undefined) {
    super.set("orderText", value);
  }
  get referenceContact(): string | undefined {
    return super.get("referenceContact");
  }
  set referenceContact(value: string | undefined) {
    super.set("referenceContact", value);
  }
  get referenceDate(): Date | undefined {
    return super.get("referenceDate");
  }
  set referenceDate(value: Date | undefined) {
    super.set("referenceDate", value);
  }
  get referenceNo(): string | undefined {
    return super.get("referenceNo");
  }
  set referenceNo(value: string | undefined) {
    super.set("referenceNo", value);
  }
  get releaseForInvoice(): boolean {
    return super.get("releaseForInvoice");
  }
  set releaseForInvoice(value: boolean) {
    super.set("releaseForInvoice", value);
  }
  get sqlCreatedAt(): Date | undefined {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value: Date | undefined) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted(): boolean {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value: boolean) {
    super.set("sqlDeleted", value);
  }
  get sqlRef(): string | undefined {
    return super.get("sqlRef");
  }
  set sqlRef(value: string | undefined) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt(): Date | undefined {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value: Date | undefined) {
    super.set("sqlUpdatedAt", value);
  }
  get sys_locked(): boolean {
    return super.get("sys_locked");
  }
  set sys_locked(value: boolean) {
    super.set("sys_locked", value);
  }
  get sys_mailDelivered(): any | undefined {
    return super.get("sys_mailDelivered");
  }
  set sys_mailDelivered(value: any | undefined) {
    super.set("sys_mailDelivered", value);
  }
  get sys_mailSent(): boolean {
    return super.get("sys_mailSent");
  }
  set sys_mailSent(value: boolean) {
    super.set("sys_mailSent", value);
  }
  get sys_pdf(): Parse.File | undefined {
    return super.get("sys_pdf");
  }
  set sys_pdf(value: Parse.File | undefined) {
    super.set("sys_pdf", value);
  }
  get type(): string | undefined {
    return super.get("type");
  }
  set type(value: string | undefined) {
    super.set("type", value);
  }
  get user(): _User | undefined {
    return super.get("user");
  }
  set user(value: _User | undefined) {
    super.set("user", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_Order", Order);
