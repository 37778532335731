import { useTranslation } from "@opendash/core";
import { Card, Divider, Image, List } from "antd";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { OrderMediaProps } from "..";
import { OrderImage } from "../../../parse";
import { $heinzerling } from "../../../service";

export default observer<OrderMediaProps>(({ order }) => {
  const t = useTranslation();
  const [images, setImages] = useState<OrderImage[]>([]);

  useMemo(
    () =>
      $heinzerling.sync
        .createQuery(OrderImage)
        .equalTo("order", order)
        .notEqualTo("deleted", true)
        .ascending("createdAt")
        .find(),
    [order?.id]
  ).then(setImages);

  if (images.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />

      <Image.PreviewGroup>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 5,
            xxl: 6,
          }}
          rowKey={(row: OrderImage) => row.id}
          dataSource={images}
          renderItem={(image: OrderImage) => {
            const alt = image.get("name");
            const src = image.get("image")?.url();
            return (
              <List.Item>
                <Card
                  hoverable
                  cover={<Image alt={alt} src={src} />}
                  children={
                    <Card.Meta
                      title={alt}
                      description={t("app:orders.fotos.card_description")}
                    />
                  }
                />
              </List.Item>
            );
          }}
        />
      </Image.PreviewGroup>
    </>
  );
});
