import Parse from "parse";

import type { Customer } from "./Customer";
import type { Facility } from "./Facility";

export interface ContactPersonAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  customer?: Customer;
  email?: string;
  facility?: Facility;
  mobile?: string;
  name: string;
  phone?: string;
  sqlCreatedAt?: Date;
  sqlDeleted: boolean;
  sqlRef: string;
  sqlUpdatedAt?: Date;
}

export class ContactPerson extends Parse.Object<ContactPersonAttributes> {
  static className: string = "Heinzerling2_ContactPerson";

  constructor(data?: Partial<ContactPersonAttributes>) {
    super("Heinzerling2_ContactPerson", data as ContactPersonAttributes);
  }

  get customer(): Customer | undefined {
    return super.get("customer");
  }
  set customer(value: Customer | undefined) {
    super.set("customer", value);
  }
  get email(): string | undefined {
    return super.get("email");
  }
  set email(value: string | undefined) {
    super.set("email", value);
  }
  get facility(): Facility | undefined {
    return super.get("facility");
  }
  set facility(value: Facility | undefined) {
    super.set("facility", value);
  }
  get mobile(): string | undefined {
    return super.get("mobile");
  }
  set mobile(value: string | undefined) {
    super.set("mobile", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get phone(): string | undefined {
    return super.get("phone");
  }
  set phone(value: string | undefined) {
    super.set("phone", value);
  }
  get sqlCreatedAt(): Date | undefined {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value: Date | undefined) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted(): boolean {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value: boolean) {
    super.set("sqlDeleted", value);
  }
  get sqlRef(): string {
    return super.get("sqlRef");
  }
  set sqlRef(value: string) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt(): Date | undefined {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value: Date | undefined) {
    super.set("sqlUpdatedAt", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_ContactPerson", ContactPerson);
