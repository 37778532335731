import { makeAutoObservable, runInAction } from "@opendash/state";
import { formatAddress } from "../../../helper/formatter";
import { Facility, OrderType, Technican } from "../../../parse";
import { $heinzerling } from "../../../service";

export class OrderCreateState {
  public loading: boolean = true;
  public facilities: Array<Facility> = [];
  public technicians: Array<Technican> = [];
  public userTechnician: Technican | null = null;
  public selectedFacility: Facility | null = null;
  public orderTypes: Array<OrderType> = [];

  constructor() {
    makeAutoObservable(this);

    this.fetch();
  }

  async fetch() {
    const user = await $heinzerling.sync.getUser();

    const userTechnician: Technican | null =
      (await user?.get("technican")) || null;

    // if (userTechnician) {
    //   await userTechnician.fetch();
    // }

    const facilities = await $heinzerling.sync
      .createQuery(Facility)
      .include("customer")
      .ascending("no")
      .limit(1_000_000)
      .find();

    const technicians = await $heinzerling.sync
      .createQuery(Technican)
      .ascending("name")
      .limit(1_000_000)
      .find();

    const orderTypes = await $heinzerling.sync
      .createQuery(OrderType)
      .ascending("abbreviation")
      .find();

    runInAction(() => {
      this.loading = false;

      this.facilities = facilities;
      this.technicians = technicians;
      this.userTechnician = userTechnician;
      this.orderTypes = orderTypes;
    });
  }

  public get facilitiesForSelect(): Array<{ label: string; value: string }> {
    return this.facilities
      .map((facility: Facility) => {
        if (typeof facility.get("customer") !== "undefined") {
          return {
            label: `[${facility.get("no")}] ${facility
              .get("customer")!
              .get("name")} - ${formatAddress(facility)}`,
            value: facility.id,
          };
        }
        return undefined;
      })
      .filter(Boolean) as Array<{ label: string; value: string }>;
  }

  public get Technicians(): Array<Technican> {
    return this.technicians;
  }

  public get TechniciansForSelect(): Array<{ label: string; value: string }> {
    return this.technicians.map((technician) => {
      return {
        label: technician.get("name2") + " " + technician.get("name"),
        value: technician.id,
      };
    });
  }

  public get SelectedFacility(): Facility | null {
    return this.selectedFacility;
  }

  public set SelectedFacility(value: Facility | null) {
    this.selectedFacility = value;
  }
}
