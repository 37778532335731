import { makeAutoObservable, runInAction } from "mobx";
import Parse from "parse";
import type { HeinzerlingService } from ".";
import { $offlineService } from "../features/offline";
import { formatDateTime } from "../helper/formatter";
import {
  ContactPerson,
  Customer,
  DeletedObjects,
  Facility,
  Item,
  Order,
  OrderFacilityRelation,
  OrderImage,
  OrderItemRelation,
  OrderTechnicanRelation,
  OrderType,
  Technican,
  TextTemplate,
} from "../parse";

const lse_key = "heinzerling/offline";
const lss_key = "heinzerling/offline-last-sync";

export class HeinzerlingOfflineService {
  private app: HeinzerlingService;

  public loading: boolean = true;
  public enabled: boolean = !!window.localStorage.getItem(lse_key) || false;
  public lastSync: number =
    parseInt(window.localStorage.getItem(lss_key) || "0") || 0;
  public _log: string[] = [];

  constructor(app: HeinzerlingService) {
    makeAutoObservable(this);
    this.app = app;

    // this.setEnabled(true);
    this.init();
  }

  public setEnabled(value: boolean) {
    this.enabled = value;
    if (value) {
      window.localStorage.setItem(lse_key, "on");

      this.init();
    } else {
      window.localStorage.removeItem(lse_key);
      this.setLastSync(0);

      Parse.User.unPinAllObjects();
      Parse.Object.unPinAllObjects();
    }
  }

  public setLastSync(value: number) {
    this.lastSync = value;

    if (value) {
      window.localStorage.setItem(lss_key, value.toString());
    } else {
      window.localStorage.removeItem(lss_key);
    }
  }

  public createQuery<T extends Parse.Object = Parse.Object>(
    cls: new (...args: any[]) => T
  ): Parse.Query<T> {
    const query = new Parse.Query<T>(cls);

    return this.patchQuery(query);
  }

  public patchQuery<T extends Parse.Object = Parse.Object>(
    query: Parse.Query<T>
  ): Parse.Query<T> {
    if (this.enabled) {
      query.fromLocalDatastore();
    }

    query.limit(1_000_000);

    return query;
  }

  public async saveObject(object: Parse.Object) {
    // If online save normally
    if ($offlineService.online) {
      await object.save();
    }

    // If offline put to queue
    if (!$offlineService.online) {
      $offlineService.jobQueue.push({
        object: object,
        action: "create",
      });
    }

    if (this.enabled) {
      await object.pin();
    }
  }

  public async deleteObject(object: Parse.Object) {
    if ($offlineService.online) {
      await object.destroy();
    }

    if (!$offlineService.online) {
      $offlineService.jobQueue.push({
        object: object,
        action: "delete",
      });
    }

    if (this.enabled) {
      await object.unPin();
    }
  }

  public async getUser() {
    if ($offlineService.online) {
      return await Parse.User.currentAsync();
    }

    if (!$offlineService.online) {
      return await new Parse.Query(Parse.User)
        .fromPinWithName("CurrentUser")
        .first();
    }
    return undefined;
  }

  public async init() {
    this.loading = true;
    this._log.length = 0;

    this.log("Synchronisation wird vorbereitet.");

    const user = await Parse.User.currentAsync();
    const now = Date.now();

    if (!this.enabled || !user) {
      runInAction(() => {
        this.loading = false;
      });

      this.log("Synchronisation wird abgebrochen.");
      return;
    }

    const start = Date.now();

    if (this.lastSync) {
      this.log(
        `Letzte Synchronisation fand am ${formatDateTime(
          new Date(this.lastSync)
        )} statt.`
      );
    } else {
      await Parse.Object.unPinAllObjects();
    }

    this.log("Synchronisation gestartet.");

    //Pin current user
    await user.pinWithName("CurrentUser");

    const classNames = [
      ContactPerson,
      Customer,
      Facility,
      Item,
      Order,
      OrderImage,
      OrderItemRelation,
      OrderTechnicanRelation,
      OrderFacilityRelation,
      Technican,
      TextTemplate,
      OrderType,
    ];

    for (const cls of classNames) {
      const query = new Parse.Query(cls).limit(1_000_000);

      if (this.lastSync) {
        query.greaterThan("updatedAt", new Date(this.lastSync));
      }

      const objects = await query.find();

      const cName = cls.className
        .replace("Heinzerling_", "")
        .replace("Heinzerling2_", "");

      this.log(`Synchronisation von ${cName}, ${objects.length} neue Elemente`);

      await Parse.Object.pinAll(objects);
    }

    if (this.lastSync) {
      this.log("Entferne gelöschte Elemente.");

      const deletedObjects = await new Parse.Query(DeletedObjects)
        .limit(1_000_000)
        .find();

      for (const o of deletedObjects) {
        try {
          const obj = await new Parse.Query(o.c).get(o.i);

          if (obj) {
            await obj.unPin();
          }
        } catch (error) {}
      }

      // await Parse.Object.unPinAll(
      //   deletedObjects.map((o) => new Parse.Object(o.c, { id: o.i }))
      // );

      this.log("Gelöschte Elemente wurden entfernt.");
    }

    this.setLastSync(now);

    this.setLoading(false);

    const ms = Date.now() - start;

    this.log(`Synchronisation in ${ms}ms abgeschlossen.`);
  }

  private log(row: string) {
    this._log.push("> " + row);
  }

  setLoading(v: boolean) {
    this.loading = v;
  }
}
