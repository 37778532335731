import { Button, Divider, Image, List } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { UpdatePhotosTabProps } from "..";

export default observer<UpdatePhotosTabProps>(function UpdatePhotosTab({
  state,
}) {
  if (!state.order) throw new Error("Invalid State");

  const ref = React.useRef<HTMLInputElement>(null);

  const [, setReload] = React.useState(0);

  return (
    <>
      <Image.PreviewGroup>
        <List
          grid={{ gutter: 16, column: 2 }}
          dataSource={state.images}
          renderItem={(image) => (
            <List.Item style={{ width: "100%" }}>
              <Image
                src={image.thumbnail}
                title={image.name}
                width="100%"
                preview={{
                  src: image.image?.url() || image.imageBase64,
                }}
              />

              <Button
                disabled={state.disabled}
                danger
                children="Löschen"
                style={{ width: "100%", marginTop: 8 }}
                onClick={() => {
                  state.destroyImage(image);
                }}
              />
            </List.Item>
          )}
        />
      </Image.PreviewGroup>

      <Divider />

      <Button
        disabled={state.disabled}
        type="primary"
        children="Foto hochladen"
        onClick={() => {
          if (ref.current) {
            ref.current.click();
          }
        }}
      />
      <input
        ref={ref}
        type="file"
        multiple
        accept="image/png,image/gif,image/jpeg"
        style={{ display: "none" }}
        onChange={(e) => {
          if (e.target.files) {
            state
              .uploadImages(Array.from(e.target.files))
              .then(() => setReload((x) => x + 1));
          }
        }}
      />
    </>
  );
});
