import { makeAutoObservable, runInAction } from "@opendash/state";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import {
  Item,
  OrderItemRelation,
  OrderTechnicanRelation,
  Technican,
  _User,
} from "../../../parse";
import { $heinzerling } from "../../../service";
import ItemsInterface from "../types/Items";

const DATE_FORMAT = "YYYY-MM-DD";

export class WeekOverviewState {
  public loading: boolean = true;
  public tab: string = "orders";

  public start: Dayjs = dayjs().startOf("isoWeek");
  public end: Dayjs = dayjs().endOf("isoWeek");

  public showFinished: boolean = false;
  public showOpen: boolean = true;
  public showOwnOrdersOnly: boolean = true;
  public groupOrdersByNo: boolean = true;

  public orders: OrderTechnicanRelation[] = [];
  public items: ItemsInterface[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setTab(tab: string) {
    this.tab = tab;
  }

  setTimerange(start: Dayjs, end: Dayjs) {
    this.start = start;
    this.end = end;
  }

  setShowOpen(value: boolean) {
    this.showOpen = value;
  }

  setShowFinished(value: boolean) {
    this.showFinished = value;
  }

  setShowOwnOrdersOnly(value: boolean) {
    this.showOwnOrdersOnly = value;
  }

  setGroupOrdersByNo(value: boolean) {
    this.groupOrdersByNo = value;
  }

  setParams(
    startParam: string | undefined,
    endParam: string | undefined,
    showFinised: boolean,
    showOpen: boolean,
    showOwnOrdersOnly: boolean,
    groupOrdersByNo: boolean
  ) {
    this.start = startParam
      ? dayjs(startParam, DATE_FORMAT)
      : dayjs().startOf("isoWeek");

    this.end = endParam
      ? dayjs(endParam, DATE_FORMAT)
      : dayjs().endOf("isoWeek");

    !this.showOpen ? (this.showOpen = showOpen) : null;
    this.showFinished = showFinised;
    !this.showOwnOrdersOnly
      ? (this.showOwnOrdersOnly = showOwnOrdersOnly)
      : null;
    !this.groupOrdersByNo ? (this.groupOrdersByNo = groupOrdersByNo) : null;

    this.load();
  }

  private async load() {
    runInAction(() => {
      this.loading = true;
      this.orders = [];
      this.items = [];
    });

    const orders: Record<string, OrderTechnicanRelation> = {};

    const orderTechnicanRelationsQuery = $heinzerling.sync
      .createQuery(OrderTechnicanRelation)
      .exists("start")
      .descending("start")
      .greaterThanOrEqualTo("start", this.start.toDate())
      .lessThanOrEqualTo("start", this.end.toDate())
      .include("order")
      .include("technican")
      // @ts-ignore
      .include("order.customer")
      // @ts-ignore
      .include("order.facility")
      .limit(1000000);

    if (this.showOwnOrdersOnly) {
      orderTechnicanRelationsQuery.equalTo(
        "technican",
        ((await $heinzerling.sync.getUser()) as _User).get(
          "technican"
        ) as Technican
      );
    }

    const orderTechnicanRelations = await orderTechnicanRelationsQuery.find();

    for (const otr of orderTechnicanRelations) {
      const order = otr.get("order");

      if (!order) {
        continue;
      }

      if (
        !this.showOpen &&
        !(order!.get("sys_locked") || order!.get("sys_mailSent"))
      ) {
        continue;
      }

      if (
        !this.showFinished &&
        (order!.get("sys_locked") || order!.get("sys_mailSent"))
      ) {
        continue;
      }

      const objkey = this.groupOrdersByNo ? order.id : order.id + otr.id;

      if (this.groupOrdersByNo && orders[objkey]) {
        continue;
      }

      orders[objkey] = otr;
    }

    const orderItems = await $heinzerling.sync
      .createQuery(OrderItemRelation)
      .containedIn(
        "order",
        Object.values(orders).map((item) => item.get("order") || "noOrder")
      )
      .doesNotExist("parentPosition")
      .include("item")
      .limit(1000000)
      .find();

    const itemsMap: Record<string, ItemsInterface> = {};

    for (const iterator of orderItems) {
      const item = iterator.get("item") as Item;
      const quantity = iterator.get("quantity");
      const orderNo = iterator.get("order")?.get("no") || "";
      const fromSync = iterator.get("fromSync");

      if (
        !item ||
        !orderNo ||
        !quantity ||
        !item.get("inPlanView") ||
        !fromSync
      ) {
        continue;
      }

      if (itemsMap[item.id]) {
        itemsMap[item.id].quantity += quantity;
        itemsMap[item.id].description += ", " + orderNo;
      } else {
        const name = item.get("name");
        const no = item.get("no");
        const key = item.id;
        const title = `${name} (#${no})`;
        const description = orderNo;
        const body = item.get("description4") || "";
        const unit = item.get("unit");

        itemsMap[item.id] = { key, title, description, body, quantity, unit };
      }
    }

    runInAction(() => {
      this.loading = false;
      this.orders = Object.values(orders);
      this.items = Object.values(itemsMap);
    });
  }
}
