import { message } from "antd";
import { makeAutoObservable } from "mobx";
import type { HeinzerlingService } from ".";
import Parse from "parse";

export class HeinzerlingHelperService {
  constructor(app: HeinzerlingService) {
    makeAutoObservable(this);
  }

  handleError(type: "save" | "query", error: unknown) {
    const prefix =
      type === "save" ? "Speichern fehlgeschlagen" : "Abfrage fehlgeschlagen";

    if (error instanceof Parse.Error) {
      message.error(`${prefix}: [${error.code}] ${error.message}`);
    } else {
      message.error(`${prefix}: Unbekannter Fehler`);
    }
  }
}
