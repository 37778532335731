import { AdminLayout, useTranslation } from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import { Button, Divider, List, Typography } from "antd";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React, { memo, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Customer, Facility } from "../../../parse";

export default memo(() => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { Title } = Typography;

  const [searchString, setSearchString] = useState<string>("");

  const query = useMemo(
    () =>
      new Parse.Query(Facility)
        .ascending("no")
        .exists("customer")
        .limit(1000000)
        .include("customer"),
    []
  );

  const entries = useParseQuery(query);

  const rows = useMemo(() => {
    const search = searchString.toLowerCase();

    return entries.result
      .filter((row) => {
        const facility = row as Facility;
        const customer = facility.get("customer") as Customer;

        if (facility.get("no")?.toString().includes(search)) {
          return true;
        }

        if (customer.get("name")?.toLowerCase().includes(search)) {
          return true;
        }

        const address = [
          facility.get("street"),
          facility.get("postcode"),
          facility.get("city"),
        ].join(" ");

        if (address.includes(search)) {
          return true;
        }

        return false;
      })
      .sort((a, b) => {
        return parseFloat(a.get("no") || "") - parseFloat(b.get("no") || "");
      });
  }, [entries.result, searchString]);

  return (
    <AdminLayout contentPadding>
      <>
        <AdminToolbar
          title={t("app:admin.elevators.list_title")}
          description={t("app:admin.elevators.list_description")}
          // actions={[
          //   <Button
          //     key="new"
          //     type="primary"
          //     icon={<Icon icon="fa:plus" />}
          //     children={t("app:admin.order.action_new")}
          //     onClick={() => {
          //       createObject("Auftrag").then((objectId) => {
          //         entries.reload();
          //       });
          //     }}
          //   />,
          // ]}
          // search={searchString}
          onSearch={setSearchString}
        />

        <List
          loading={entries.loading}
          dataSource={rows}
          renderItem={(row) => {
            const facility = row as Facility;
            const customer = facility.get("customer") as Customer;

            return (
              <List.Item
                actions={[
                  <Button
                    key="show"
                    type="primary"
                    children={t("app:admin.elevators.action_show")}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/elevators/${facility.id}`);
                    }}
                  />,
                ]}
              >
                <List.Item.Meta
                  title={t("app:admin.elevators.view_title", {
                    name: facility.get("no") || "",
                  })}
                  description={
                    <span>
                      {customer.get("name")} - {facility.get("street")}{" "}
                      {facility.get("postcode")} {facility.get("city")}
                    </span>
                  }
                />
              </List.Item>
            );
          }}
          rowKey={(row) => row.id}
          pagination={{
            defaultCurrent: 1,
            defaultPageSize: 10,
          }}
          footer={<Divider />}
        />
      </>
      {/* <Detector
        offlineChildren={
          <Empty
            style={{ marginTop: 24 }}
            children="Sie sind offline. Die Aufzüge können nicht angezeigt werden."
            description={<Title level={5}>OFFLINE</Title>}
          />
        }
        onlineChildren={
          
        }
      /> */}
    </AdminLayout>
  );
});
