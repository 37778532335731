import { OrderItemRelation } from "../../../parse";
import { OrderState } from "../../orders/states/OrderState";

// This is a fixed prefix to be added to order position to distinguish them from positions created in orderbase.
const orderPositionPrefix: number = 100;

export function calculatePosition(
  parent: OrderItemRelation | null,
  state: OrderState
): number | undefined {
  let calculatedposition = 0;
  if (parent) {
    return undefined;
  }

  const maxposition = Math.max(...state.items.map((i) => i.position || 0));

  if (maxposition > orderPositionPrefix) {
    // The position belongs to the area of items created by the app
    calculatedposition =
      orderPositionPrefix + (maxposition - orderPositionPrefix + 1);
  } else {
    calculatedposition = orderPositionPrefix + (maxposition + 1);
  }

  return calculatedposition;
}
