import { makeAutoObservable } from "@opendash/state";
import dayjs from "dayjs";
import { OrderTechnicanRelation, Technican, _User } from "../../../parse";
import { $heinzerling } from "../../../service";

export class CalendarState {
  public orders: OrderTechnicanRelation[] = [];

  public start: number = dayjs().startOf("day").valueOf();
  public end: number = dayjs().endOf("day").valueOf();

  public get startDate(): Date {
    return new Date(this.start);
  }

  public get endDate(): Date {
    return new Date(this.end);
  }

  constructor() {
    makeAutoObservable(this);

    // this.start = dayjs("2023-04-01").valueOf();
    // this.end = dayjs().endOf("year").valueOf();

    this.fetch();
  }

  async fetch() {
    const technicanOrders = await $heinzerling.sync
      .createQuery(OrderTechnicanRelation)
      .greaterThanOrEqualTo("start", this.startDate)
      .lessThanOrEqualTo("start", this.endDate)
      .equalTo(
        "technican",
        ((await $heinzerling.sync.getUser()) as _User).get(
          "technican"
        ) as Technican
      )
      .ascending("start")
      .include("order")
      // @ts-ignore
      .include("order.customer")
      //@ts-ignore
      .include("order.facility")
      .find();

    //Filter orders that are not created on mobile and get only the ones that are from sync to get the execution date, not the creation date
    this.setOrders(
      technicanOrders.filter((technicianOrder) => {
        if (!technicianOrder.get("order")?.get("local_createdOnMobile")) {
          return technicianOrder.get("fromSync");
        } else {
          return true;
        }
      })
    );
  }

  setOrders(orders: OrderTechnicanRelation[]) {
    this.orders = orders.filter((to) => !!to.order?.id);
  }

  setTimerange(start: Date, end: Date) {
    this.start = start.valueOf();
    this.end = end.valueOf();

    this.fetch();
  }
}
