import Parse from "parse";

import type { Order } from "./Order";

export interface OrderImageAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  deleted: boolean;
  image?: Parse.File;
  imageBase64?: string;
  name?: string;
  order?: Order;
  thumbnail?: string;
  writtenToDisk: boolean;
}

export class OrderImage extends Parse.Object<OrderImageAttributes> {
  static className: string = "Heinzerling2_OrderImage";

  constructor(data?: Partial<OrderImageAttributes>) {
    super("Heinzerling2_OrderImage", data as OrderImageAttributes);
  }

  get deleted(): boolean {
    return super.get("deleted");
  }
  set deleted(value: boolean) {
    super.set("deleted", value);
  }
  get image(): Parse.File | undefined {
    return super.get("image");
  }
  set image(value: Parse.File | undefined) {
    super.set("image", value);
  }
  get imageBase64(): string | undefined {
    return super.get("imageBase64");
  }
  set imageBase64(value: string | undefined) {
    super.set("imageBase64", value);
  }
  get name(): string | undefined {
    return super.get("name");
  }
  set name(value: string | undefined) {
    super.set("name", value);
  }
  get order(): Order | undefined {
    return super.get("order");
  }
  set order(value: Order | undefined) {
    super.set("order", value);
  }
  get thumbnail(): string | undefined {
    return super.get("thumbnail");
  }
  set thumbnail(value: string | undefined) {
    super.set("thumbnail", value);
  }
  get writtenToDisk(): boolean {
    return super.get("writtenToDisk");
  }
  set writtenToDisk(value: boolean) {
    super.set("writtenToDisk", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_OrderImage", OrderImage);
