import { message } from "antd";
import { makeAutoObservable } from "mobx";
import { UpdateItemState } from "..";
import { OrderItemRelation } from "../../../parse";
import { OrderState } from "../../orders/states/OrderState";

export class PartsListState {
  private _orderstate: OrderState;
  private _updateitemstate: UpdateItemState;
  private _open: boolean = false;
  private _name: string = "";
  private _disabled: boolean = false;
  private _orderitemrelation: OrderItemRelation | null = null;

  constructor(oir: OrderItemRelation, state: OrderState) {
    this._orderstate = state;
    this._orderitemrelation = oir;
    this._updateitemstate = new UpdateItemState(this.orderstate);
    makeAutoObservable(this);
  }

  public get open(): boolean {
    return this._open;
  }

  public set open(v: boolean) {
    this._open = v;
  }

  public get name(): string {
    return this._name;
  }

  public set name(v: string) {
    this._name = v;
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(v: boolean) {
    this._disabled = v;
  }

  public get orderstate(): OrderState {
    return this._orderstate;
  }

  public get updateitemstate(): UpdateItemState {
    return this._updateitemstate;
  }

  public get parts() {
    const parent = this._orderitemrelation;

    if (!parent) {
      return [];
    }

    return this._orderstate.items.filter(
      (i) =>
        i.parentPosition === parent.position &&
        !i.local_deleted &&
        !i.get("item")?.get("resource")
    );
  }

  public getPartQuantity(ir: OrderItemRelation) {
    return (ir.local_quantity ?? ir.quantity) + " " + ir.item?.unit;
  }

  public getPartTitle(ir: OrderItemRelation) {
    let title = "";
    if (typeof ir.item === "undefined") {
      title = ir.description;
    }

    if (typeof ir.item !== "undefined" && title.length === 0) {
      title = ir.item.name || ir.item.description4;
    }

    // If this is a dummy then display the description from the order
    if (title.toLowerCase() === "dummy") {
      title = ir.description;
    }

    return title;
  }

  public showPartsList(orderitemrelation: OrderItemRelation): boolean {
    return (
      this._orderstate.items.filter(
        (ir) => orderitemrelation.position === ir.parentPosition
      ).length > 0
    );
  }

  public copyPartsListToClipboard() {
    const copyText = this.parts
      .map((part) => this.getPartQuantity(part) + " " + this.getPartTitle(part))
      .join("\r\n");

    navigator.clipboard.writeText(copyText).then(
      () => {
        message.success("Kopiert", 5);
      },
      () => {
        message.error("Fehler beim Kopieren", 5);
      }
    );
  }

  public copyPartToClipboard(part: OrderItemRelation) {
    const copyText = this.getPartQuantity(part) + " " + this.getPartTitle(part);

    navigator.clipboard.writeText(copyText).then(
      () => {
        message.success("Kopiert", 5);
      },
      () => {
        message.error("Fehler beim Kopieren", 5);
      }
    );
  }

  // No functionality currently because it is not supported by orderbase
  public addItem() {}

  public setUpdateDialog(value: OrderItemRelation) {
    this._updateitemstate.itemrelation = value;
    this._updateitemstate.open = true;
  }
}
