import Parse from "parse";

import type { Item } from "./Item";
import type { Order } from "./Order";

export interface OrderItemRelationAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  description: string;
  description2: string;
  description3: string;
  description4: string;
  fromSync: boolean;
  item?: Item;
  local_deleted: boolean;
  local_quantity?: number;
  order: Order;
  parentPosition?: number;
  position?: number;
  quantity: number;
  sqlCreatedAt?: Date;
  sqlDeleted: boolean;
  sqlRef?: string;
  sqlUpdatedAt?: Date;
  sys_app_id: number;
}

export class OrderItemRelation extends Parse.Object<OrderItemRelationAttributes> {
  static className: string = "Heinzerling2_OrderItemRelation";

  constructor(data?: Partial<OrderItemRelationAttributes>) {
    super("Heinzerling2_OrderItemRelation", data as OrderItemRelationAttributes);
  }

  get description(): string {
    return super.get("description");
  }
  set description(value: string) {
    super.set("description", value);
  }
  get description2(): string {
    return super.get("description2");
  }
  set description2(value: string) {
    super.set("description2", value);
  }
  get description3(): string {
    return super.get("description3");
  }
  set description3(value: string) {
    super.set("description3", value);
  }
  get description4(): string {
    return super.get("description4");
  }
  set description4(value: string) {
    super.set("description4", value);
  }
  get fromSync(): boolean {
    return super.get("fromSync");
  }
  set fromSync(value: boolean) {
    super.set("fromSync", value);
  }
  get item(): Item | undefined {
    return super.get("item");
  }
  set item(value: Item | undefined) {
    super.set("item", value);
  }
  get local_deleted(): boolean {
    return super.get("local_deleted");
  }
  set local_deleted(value: boolean) {
    super.set("local_deleted", value);
  }
  get local_quantity(): number | undefined {
    return super.get("local_quantity");
  }
  set local_quantity(value: number | undefined) {
    super.set("local_quantity", value);
  }
  get order(): Order {
    return super.get("order");
  }
  set order(value: Order) {
    super.set("order", value);
  }
  get parentPosition(): number | undefined {
    return super.get("parentPosition");
  }
  set parentPosition(value: number | undefined) {
    super.set("parentPosition", value);
  }
  get position(): number | undefined {
    return super.get("position");
  }
  set position(value: number | undefined) {
    super.set("position", value);
  }
  get quantity(): number {
    return super.get("quantity");
  }
  set quantity(value: number) {
    super.set("quantity", value);
  }
  get sqlCreatedAt(): Date | undefined {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value: Date | undefined) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted(): boolean {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value: boolean) {
    super.set("sqlDeleted", value);
  }
  get sqlRef(): string | undefined {
    return super.get("sqlRef");
  }
  set sqlRef(value: string | undefined) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt(): Date | undefined {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value: Date | undefined) {
    super.set("sqlUpdatedAt", value);
  }
  get sys_app_id(): number {
    return super.get("sys_app_id");
  }
  set sys_app_id(value: number) {
    super.set("sys_app_id", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_OrderItemRelation", OrderItemRelation);
