import { Icon } from "@opendash/icons";
import { Button, Checkbox, Drawer, Form, Input, List, Space } from "antd";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { UpdateTextsState, UpdateTextsTabProps } from "..";

export default observer<UpdateTextsTabProps>(function UpdateTextsTab({
  state,
}) {
  if (!state.order) throw new Error("Invalid State");

  const x = React.useMemo(() => new UpdateTextsState(state.order!), []);
  const [editFinishedText, setEditFinishedText] = React.useState(false);
  const [editDocumentText, setEditDocumentText] = React.useState(false);
  return (
    <>
      <Form layout="vertical">
        <Form.Item>
          <Checkbox
            children="Weitere Arbeiten gewünscht oder erforderlich. Details bei Bemerkungen eintragen."
            disabled={state.disabled}
            checked={x.local_moreWorkNecessary}
            onChange={(e) =>
              runInAction(() => {
                x.local_moreWorkNecessary = e.target.checked;
                x.save();
                //x.dirty = true;
              })
            }
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            children="Notdiensteinsatz"
            disabled={state.disabled}
            checked={x.local_emergencyService}
            onChange={(e) =>
              runInAction(() => {
                x.local_emergencyService = e.target.checked;
                x.save();
                //x.dirty = true;
              })
            }
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            children="Äußere Gewalteinwirkung"
            disabled={state.disabled}
            checked={x.local_vandalism}
            onChange={(e) =>
              runInAction(() => {
                x.local_vandalism = e.target.checked;
                x.save();
                //x.dirty = true;
              })
            }
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            children="Überspannungsschaden"
            disabled={state.disabled}
            checked={x.local_overvoltageDamage}
            onChange={(e) =>
              runInAction(() => {
                x.local_overvoltageDamage = e.target.checked;
                x.save();
                //x.dirty = true;
              })
            }
          />
        </Form.Item>

        <Form.Item label="Erledigungstext">
          <Space direction="vertical" style={{ width: "100%" }}>
            {!state.disabled && editFinishedText && (
              <Space style={{ float: "right" }}>
                <Button
                  children={<Icon icon="fa:undo" />}
                  //disabled={!x.dirty || state.disabled}
                  onClick={() => {
                    setEditFinishedText(false);
                    x.reset();
                  }}
                />
                <Button
                  type="primary"
                  children={<Icon icon="fa:save" />}
                  //disabled={!x.dirty || state.disabled}
                  onClick={() => {
                    setEditFinishedText(false);
                    x.save();
                  }}
                />
              </Space>
            )}
            <Input.TextArea
              disabled={state.disabled || !editFinishedText}
              value={x.finishedText}
              onChange={(e) => x.setFinishedText(e.target.value)}
              autoSize={{ minRows: 6 }}
            />
            {!state.disabled && editFinishedText && (
              <Button
                disabled={state.disabled}
                children="Entwurf einfügen"
                onClick={() => x.setInstertTemplate("finishedText")}
              />
            )}
            {!state.disabled && !editFinishedText && (
              <Button
                disabled={state.disabled}
                children="Bearbeiten"
                onClick={() => setEditFinishedText(true)}
              />
            )}
          </Space>
        </Form.Item>
        <Form.Item label="Bemerkungen">
          <Space direction="vertical" style={{ width: "100%" }}>
            {!state.disabled && editDocumentText && (
              <Space style={{ float: "right" }}>
                <Button
                  children={<Icon icon="fa:undo" />}
                  //disabled={!x.dirty || state.disabled}
                  onClick={() => {
                    setEditDocumentText(false);
                    x.reset();
                  }}
                />
                <Button
                  type="primary"
                  children={<Icon icon="fa:save" />}
                  //disabled={!x.dirty || state.disabled}
                  onClick={() => {
                    setEditDocumentText(false);
                    x.save();
                  }}
                />
              </Space>
            )}
            <Input.TextArea
              disabled={state.disabled || !editDocumentText}
              value={x.documentText}
              onChange={(e) => x.setDocumentText(e.target.value)}
              autoSize={{ minRows: 6 }}
            />
            {!state.disabled && editDocumentText && (
              <Button
                disabled={state.disabled}
                children="Entwurf einfügen"
                onClick={() => x.setInstertTemplate("documentText")}
              />
            )}
            {!state.disabled && !editDocumentText && (
              <Button
                disabled={state.disabled}
                children="Bearbeiten"
                onClick={() => setEditDocumentText(true)}
              />
            )}
          </Space>
        </Form.Item>
      </Form>

      <Drawer
        title="Textvorlage einfügen"
        placement="bottom"
        open={!!x.insertTemplate}
        onClose={() => x.setInstertTemplate(null)}
      >
        <List
          dataSource={state.templates}
          renderItem={(template) => (
            <List.Item
              actions={[<Icon icon="fa:paste" />]}
              onClick={() => x.instertTemplateText(template.text)}
              style={{ cursor: "pointer" }}
            >
              <List.Item.Meta
                title={template.title}
                description={template.text}
              />
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
});
