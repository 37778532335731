import { AdminLayout } from "@opendash/core";
import { Result, Typography } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useQuery } from "../../hooks/useQuery";

const { Title, Paragraph, Text, Link } = Typography;

export const OfflineNotice = observer(() => {
  const query = useQuery();
  const action = query.get("action");

  function getMessage() {
    if (!action) {
      return (
        <Result
          status="warning"
          title="Entschuldigung, es ist ein Problem aufgetreten"
        />
      );
    }

    if (action === "order_created") {
      return (
        <Result
          title="Der Auftrag wurde angelegt"
          extra={
            <Paragraph>
              Sie sind aktuell offline. Der Auftrag wurde erstellt und wird
              synchronisiert, sobald Sie online sind. Danach können Sie ihn
              aufrufen.
            </Paragraph>
          }
        />
      );
    }
    return <p></p>;
  }

  return <AdminLayout contentPadding>{getMessage()}</AdminLayout>;
});
