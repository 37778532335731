import { AdminLayout } from "@opendash/core";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { $offlineService } from "../../features/offline";
import { $heinzerling } from "../../service";

const Code = styled.pre`
  padding: 16px;
  font-size: 85%;
  background: hsla(0, 0%, 58.8%, 0.1);
  border: 1px solid hsla(0, 0%, 39.2%, 0.2);
  border-radius: 3px;
  margin-bottom: 20px;
`;

export const PhoneSetup = observer(() => {
  const enabled = $heinzerling.sync.enabled;

  return (
    <AdminLayout contentPadding>
      <p style={{ textAlign: "center" }}>
        Offline Synchronisation ist {enabled ? "aktiviert" : "deaktiviert"}.
      </p>
      <p style={{ textAlign: "center" }}>
        Sie sind aktuell {$offlineService.online ? "online" : "offline"}.
      </p>

      {enabled && <Code>{$heinzerling.sync._log.join("\n")}</Code>}

      {$offlineService.online ? null : (
        <>
          <p style={{ textAlign: "center" }}>
            Folgende Jobs müssen synchronisiert werden.
          </p>
          {$offlineService.jobQueue.length === 0 ? (
            <Code>...keine Jobs</Code>
          ) : (
            <Code>
              {$offlineService.jobQueue
                .map((job) => {
                  return `${job.object.className} ${job.action}`;
                })
                .join("\n")}
            </Code>
          )}
        </>
      )}

      {enabled && (
        <Button
          children="Synchronisation starten"
          disabled={$heinzerling.sync.loading}
          onClick={() => {
            $heinzerling.sync.init();
          }}
          style={{
            width: "100%",
            marginBottom: 20,
          }}
        />
      )}

      {enabled && (
        <Button
          children="Komplette Synchronisation starten"
          disabled={$heinzerling.sync.loading}
          onClick={() => {
            $heinzerling.sync.setLastSync(0);
            $heinzerling.sync.init();
          }}
          style={{
            width: "100%",
            marginBottom: 20,
          }}
        />
      )}

      <Button
        type="primary"
        children={enabled ? "Deaktivieren" : "Aktivieren"}
        onClick={() => {
          $heinzerling.sync.setEnabled(!enabled);
        }}
        style={{
          width: "100%",
        }}
      />
    </AdminLayout>
  );
});
