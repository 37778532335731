import { useTranslation } from "@opendash/core";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import React from "react";
import { createGlobalStyle } from "styled-components";
import { WeeklyHeaderProps } from "..";

const GlobalStyle = createGlobalStyle`
  .ant-picker {
    padding: 15px;
    margin-bottom: 0 !important;
  }
  .ant-picker-panel-container,
  .ant-picker-panel,
  .ant-picker-date-panel,
  .ant-picker-week-panel {
    width: 100% !important;
  }
  .ant-picker-date-panel .ant-picker-content{
    width: 100% !important;
  }
`;

export default observer<WeeklyHeaderProps>(({ start, end, onChange }) => {
  const t = useTranslation();

  return (
    <>
      {/* <GlobalStyle /> */}
      <DatePicker
        value={dayjs(start)}
        size="large"
        // picker="week"
        // format={(value: Dayjs) => {
        //   return "Woche " + value.format("WW in YYYY");
        // }}
        style={{ width: "100%", marginBottom: 30 }}
        onChange={(v) => {
          if (v) {
            onChange(v.startOf("day").toDate(), v.endOf("day").toDate());
          }
        }}
      />
    </>
  );
});
