import Parse from "parse";

export interface DeletedObjectsAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  c: string;
  i: string;
}

export class DeletedObjects extends Parse.Object<DeletedObjectsAttributes> {
  static className: string = "Heinzerling2_DeletedObjects";

  constructor(data?: Partial<DeletedObjectsAttributes>) {
    super("Heinzerling2_DeletedObjects", data as DeletedObjectsAttributes);
  }

  get c(): string {
    return super.get("c");
  }
  set c(value: string) {
    super.set("c", value);
  }
  get i(): string {
    return super.get("i");
  }
  set i(value: string) {
    super.set("i", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_DeletedObjects", DeletedObjects);
