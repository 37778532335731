import { Icon } from "@opendash/icons";
import { Alert, Collapse, Empty, Tag, Timeline } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { Offline } from "../../offline/components/Offline";
import { HistoryTabProps } from "../types/HistoryTabProps";

export default observer<HistoryTabProps>(function HistoryTab({ state }) {
  return (
    <>
      <Offline
        children={
          <Alert
            message="Sie sind offline"
            type="warning"
            description="Es werden eventuell nicht alle Daten angezeigt."
            style={{ marginBottom: 12 }}
          />
        }
      />
      <Collapse
        items={state.history.map((historyitem) => {
          const timelineitems = historyitem.orders
            .filter((order) => order.get("no") !== state.order?.get("no"))
            .map((order) => {
              return {
                key: order.id,
                label: (
                  <>
                    {new Date(order.get("date")!).toLocaleDateString("de-de")} @{" "}
                    {new Date(order.get("date")!).toLocaleString("de-de", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </>
                ),
                children: (
                  <>
                    <Tag>{order.get("type")}</Tag>

                    <Link to={`/phone/order/${order.id}/view-overview`}>
                      Auftrag {order.get("no")} <Icon icon="fa:external-link" />
                    </Link>

                    <br />

                    {order.get("introductionText") &&
                    order.get("introductionText")!.length > 0 ? (
                      <>
                        <br />
                        {order.get("introductionText")}
                      </>
                    ) : null}
                    {order.get("orderText") &&
                    order.get("orderText")!.length > 0 ? (
                      <>
                        <br />
                        {order.get("orderText")}
                      </>
                    ) : null}

                    <br />

                    {order.get("sys_pdf") ? (
                      <Link to={order.get("sys_pdf")!.url()} target="_blank">
                        Auftragsbericht <Icon icon="fa:external-link" />
                      </Link>
                    ) : null}
                  </>
                ),
              };
            });
          return {
            key: historyitem.no,
            label: `${historyitem.no} (${historyitem.street}, ${historyitem.postcode} ${historyitem.city})`,
            children: (
              <>
                {/* <Descriptions
                bordered
                size="small"
                style={{ marginBottom: 30 }}
                column={1}
                items={[
                  {
                    label: t("app:classes.Facility.fields.no"),
                    children: facility.no,
                    },
                    ]}
              /> */}
                {timelineitems.length === 0 ? (
                  <Empty description="Keine Historie" />
                ) : (
                  <Timeline mode="left" items={timelineitems} />
                )}
              </>
            ),
          };
        })}
        defaultActiveKey={state.history.length > 0 ? state.history[0].no : ""}
      />
    </>
  );
});
