import { Button, Drawer, Form, InputNumber, Select, Space } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { UpdateItemProps } from "..";

export default observer<UpdateItemProps>(({ state }) => {
  return (
    <Drawer
      title="Artikel bearbeiten"
      placement="bottom"
      open={!!state.open}
      onClose={() => (state.open = false)}
    >
      <Form>
        <Form.Item
          label="Artikel"
          validateStatus={state.item === null ? "error" : "success"}
        >
          <Select
            showSearch
            optionFilterProp="children"
            placeholder="Wähle einen Artikel aus"
            options={state.itemOptions}
            value={state.item?.id}
            onChange={(value) => {
              state.item = value;
            }}
            onSearch={(searchString) => {
              state.optionsSearch = searchString || "";
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Menge"
          validateStatus={state.quantity === null ? "error" : "success"}
        >
          <InputNumber
            value={state.quantity}
            decimalSeparator=","
            onChange={(value) => {
              state.quantity = value;
            }}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              type="primary"
              children="Speichern"
              onClick={() => {
                state.save();
              }}
            />
            <Button
              danger
              type="primary"
              children="Löschen"
              onClick={() => {
                state.delete();
              }}
            />
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
});
