import { translateSync } from "@opendash/i18n";
import { message } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import Parse from "parse";
import { TabValues } from "..";
import { formatDate } from "../../../helper/formatter";
import {
  ContactPerson,
  Facility,
  Order,
  OrderFacilityRelation,
  OrderImage,
  OrderItemRelation,
  OrderTechnicanRelation,
  TextTemplate,
} from "../../../parse";
import { $heinzerling } from "../../../service";
import { $offlineService } from "../../offline";
import { HistoryProps } from "../../phone/types/HistoryTabProps";

const menu = [
  {
    key: "view-overview",
    iconKey: "fa:info-circle",
    label: "Übersicht",
    view: true,
    update: false,
  },
  {
    key: "view-navigation",
    iconKey: "fa:location-arrow",
    label: "Navigation",
    view: true,
    update: false,
  },
  {
    key: "view-contacts",
    iconKey: "fa:address-card",
    label: "Ansprechpartner",
    view: true,
    update: false,
  },
  {
    key: "view-history",
    iconKey: "fa:history",
    label: "Historie",
    view: true,
    update: false,
  },
  {
    key: "update-technicans",
    iconKey: "fa:pencil",
    label: "Bearbeiten",
    view: true,
    update: false,
  },
  {
    key: "update-technicans",
    iconKey: "fa:users",
    label: "Monteur(in)",
    view: false,
    update: true,
  },
  {
    key: "update-items",
    iconKey: "fa:boxes",
    label: "Material",
    view: false,
    update: true,
  },
  {
    key: "update-texts",
    iconKey: "fa:pencil",
    label: "Texte",
    view: false,
    update: true,
  },
  {
    key: "update-photos",
    iconKey: "fa:images",
    label: "Fotos",
    view: false,
    update: true,
  },
];

export class OrderState {
  public loading: boolean = true;
  public notFound: boolean = false;

  public tab: TabValues = "view-overview";
  public id: string;
  public rel: string | undefined;

  public order: Order | null = null;
  public facilities: Facility[] = [];
  public contacts: ContactPerson[] = [];
  public technicans: OrderTechnicanRelation[] = [];
  public items: OrderItemRelation[] = [];
  public images: OrderImage[] = [];
  public textTemplates: TextTemplate[] = [];
  public relationdescription: string = "";
  public history: Array<HistoryProps> = [];

  constructor(id: string, rel?: string) {
    this.id = id;
    this.rel = rel;

    makeAutoObservable(this);

    this.fetch();
  }

  get disabled() {
    return !!(
      this.order?.local_signatureTimestamp ||
      this.order?.local_signatureCustomer ||
      this.order?.local_signatureTechnican
    );
  }

  get title() {
    return `Auftrag #${this.order?.no}`;
  }

  get backLink(): string {
    if (this.tab.startsWith("update")) {
      return `/phone/order/${this.id}/${menu[0].key}`;
    } else {
      return "/calendar";
    }
  }

  get menu() {
    return menu
      .filter((m) => (this.tab.startsWith("update-") ? m.update : m.view))
      .map((m) => ({
        ...m,
        url: this.rel
          ? `/phone/order/${this.id}/${m.key}?rel=${this.rel}`
          : `/phone/order/${this.id}/${m.key}`,
      }));
  }

  get currentMenu() {
    return this.menu.find((m) => m.key === this.tab);
  }

  get templates() {
    return [
      {
        title: translateSync("app:classes.Order.fields.orderText"),
        text: this.order?.get("orderText") || "",
      },
      {
        title: translateSync("app:classes.Order.fields.finishedText"),
        text: this.order?.get("finishedText") || "",
      },
      ...this.textTemplates.map((t) => ({ title: t.title, text: t.text })),
    ];
  }

  getItemsSummary() {
    return this.items
      .filter((x) => !x.local_deleted && x.position !== undefined)
      .sort((a, b) => a.position! - b.position!)
      .map((x) => ({
        key: x.id,
        name: x.description4 || x.item?.description4 || x.item?.name || "-",
        position: x.position,
        unit: x.item?.unit,
        quantity: x.item ? x.local_quantity ?? x.quantity ?? 0 : 0,
      }));
  }

  getTechnicansSummary() {
    return this.technicans
      .filter((x) => !x.local_deleted && !x.local_hideFromCustomer)
      .sort((a, b) => a.start.valueOf() - b.start.valueOf())
      .map((x) => ({
        key: x.id,
        name: x.technican.name,
        date: formatDate(x.start),
        hours: x.local_hours ?? x.hours ?? 0,
        other: x.outsideBusinessHours ? "🌙" : "☀️",
      }));
  }

  setTab(tab: string | undefined) {
    if (tab) {
      this.tab = tab as TabValues;
    } else {
      this.tab = "view-overview";
    }
  }

  async fetch() {
    runInAction(() => {
      this.loading = true;
    });

    try {
      const order = await $heinzerling.sync
        .createQuery(Order)
        .include("customer")
        .include("facility")
        .get(this.id);

      const otherFacilities = await $heinzerling.sync
        .createQuery(OrderFacilityRelation)
        .equalTo("order", order)
        .include("facility")
        .find();

      const facilities = [
        order.facility,
        ...otherFacilities
          .map((x) => x.facility)
          .filter((x) => x.id !== order.facility.id),
      ];

      const contacts = [
        ...(await $heinzerling.sync
          .createQuery(ContactPerson)
          .equalTo("customer", order.customer)
          .find()),
        ...(await $heinzerling.sync
          .createQuery(ContactPerson)
          .containedIn("facility", facilities)
          .find()),
      ];

      const technicans = await $heinzerling.sync
        .createQuery(OrderTechnicanRelation)
        .equalTo("order", order)
        .notEqualTo("local_deleted", true)
        .include("technican")
        .find();

      const items = await $heinzerling.sync
        .createQuery(OrderItemRelation)
        .equalTo("order", order)
        .notEqualTo("local_deleted", true)
        .include("item")
        .ascending("position")
        .find();

      const images = await $heinzerling.sync
        .createQuery(OrderImage)
        .equalTo("order", order)
        .notEqualTo("deleted", true)
        .find();

      const textTemplates = await $heinzerling.sync
        .createQuery(TextTemplate)
        .find();

      const orderwithfacility = await $heinzerling.sync
        .createQuery(Order)
        .equalTo("facility", order.get("facility"))
        .find();

      let relationdescription: OrderTechnicanRelation;
      if (this.rel) {
        relationdescription = await $heinzerling.sync
          .createQuery(OrderTechnicanRelation)
          .get(this.rel);
      }

      let additionalhistoryitems: Order[] = [];

      if ($offlineService.online) {
        const user = await $heinzerling.sync.getUser();
        if (typeof user !== "undefined" && user !== null) {
          additionalhistoryitems = await Parse.Cloud.run(
            "heinzerling-history-items",
            { user: user.id }
          );
        }
      }

      runInAction(() => {
        this.order = order;
        this.facilities = facilities;
        this.contacts = contacts;
        this.technicans = technicans;
        this.items = items;
        this.images = images;
        this.textTemplates = textTemplates;
        this.relationdescription = relationdescription
          ? relationdescription.get("description")!
          : "";

        this.history = facilities.map((facility: Facility) => {
          return {
            ...facility.attributes,
            orders: orderwithfacility.concat(
              additionalhistoryitems ? additionalhistoryitems : []
            ),
          } as HistoryProps;
        });
      });
    } catch (error) {
      runInAction(() => {
        this.notFound = true;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async uploadImages(files: File[]) {
    if (!this.order) throw new Error("Invalid State");

    const hide = message.loading("Lade Bilder hoch...", 0);

    for (const file of files) {
      const imageBase64 = await this.fileToBase64(file);
      const scaledForUpload = await this.scaleImage(imageBase64, 1920, 1080);
      const thumbnail = await this.resizeImage(imageBase64, 400, 400);

      const image = new OrderImage({
        order: this.order,
        name: file.name,
        imageBase64: scaledForUpload,
        thumbnail,
      });

      await $heinzerling.sync.saveObject(image);

      // TODO: This will not cause a new render
      runInAction(() => {
        this.images.push(image);
      });
    }

    hide();

    message.success("Bilder wurden hochgeladen");
  }

  async destroyImage(img: OrderImage) {
    img.set("deleted", true);

    await $heinzerling.sync.saveObject(img);

    runInAction(() => {
      this.images = this.images.filter((i) => !i.deleted);
    });

    message.success("Das Bild wurde gelöscht.");
  }

  private fileToBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  private resizeImage(imageUrl: string, width: number, height: number) {
    const img = document.createElement("img");
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d")!;

    if (!ctx) {
      throw new Error("Canvas is missing 2d context");
    }

    return new Promise<string>((resolve, reject) => {
      img.onload = function (event) {
        canvas.width = width;
        canvas.height = height;

        const scaleWidth = img.width / width;
        const scaleHeight = img.height / height;

        if (scaleWidth > scaleHeight) {
          const size = img.width / scaleHeight;
          const overflow = ((size - width) / 2) * -1;

          ctx.drawImage(img, overflow, 0, size, height);
        } else {
          const size = img.height / scaleWidth;
          const overflow = ((size - height) / 2) * -1;

          ctx.drawImage(img, 0, overflow, width, size);
        }

        const base64 = canvas.toDataURL("image/jpeg", 0.7);
        // const base64 = canvas.toDataURL();

        resolve(base64);
      };

      img.src = imageUrl;
    });
  }
  private scaleImage(imageUrl: string, width: number, height: number) {
    const img = document.createElement("img");
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d")!;

    if (!ctx) {
      throw new Error("Canvas is missing 2d context");
    }

    return new Promise<string>((resolve, reject) => {
      img.onload = function (event) {
        if (img.width >= img.height) {
          canvas.width = width;
          canvas.height = img.height * (width / img.width);
        } else {
          canvas.height = height;
          canvas.width = img.width * (height / img.height);
        }

        const scaleWidth = img.width / width;
        const scaleHeight = img.height / height;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const base64 = canvas.toDataURL("image/jpeg", 0.7);
        // const base64 = canvas.toDataURL();

        resolve(base64);
      };

      img.src = imageUrl;
    });
  }
}
