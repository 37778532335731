import { Icon } from "@opendash/icons";
import { Button, List } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { NavigationTabProps } from "..";
import { formatAddress } from "../../../helper/formatter";

export default observer<NavigationTabProps>(function NavigationTab({ state }) {
  return (
    <List
      dataSource={state.facilities}
      renderItem={(facility) => {
        const address = formatAddress(facility, true) || "";

        return (
          <List.Item
            actions={[
              <Button
                disabled={!address}
                onClick={() => {
                  const params = new URLSearchParams();

                  params.set("api", "1");
                  params.set("destination", address);
                  params.set("travelmode ", "driving");
                  params.set("dir_action ", "navigate");

                  const url =
                    "https://www.google.com/maps/dir/?" + params.toString();

                  console.log(url);
                  window.open(url);
                }}
              >
                <Icon icon="fa:map-marked-alt" />
              </Button>,
            ]}
          >
            <List.Item.Meta title={facility.name} description={address} />
          </List.Item>
        );
      }}
    />
  );
});
