import { AdminLayout } from "@opendash/core";
import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { OrdersList } from "../../features/orders";
import { CalendarState, WeeklyHeader } from "../../features/phone";

export const PhoneCalendarPage = observer(() => {
  const state = useMemo(() => new CalendarState(), []);

  return (
    <AdminLayout contentPadding>
      <WeeklyHeader
        start={state.startDate}
        end={state.endDate}
        onChange={(start, end) => state.setTimerange(start, end)}
      />

      <Divider children="Aufträge" />

      <OrdersList
        rows={state.orders}
        loading={false}
        hideElevatorLink={true}
        linkToMobile={true}
        allowSelection
      />
    </AdminLayout>
  );
});
