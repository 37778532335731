import { observer } from "mobx-react-lite";
import React from "react";
import { $offlineService } from "..";

type OfflineComponentType = {
  children: any;
  onlineChildren?: any;
  onChange?: (online: boolean) => void;
};

export const Offline = observer((props: OfflineComponentType) => {
  $offlineService.addChangeHandler(props.onChange);

  if ($offlineService.online && props.onlineChildren) {
    return <>{props.onlineChildren}</>;
  }

  if ($offlineService.online && !props.onlineChildren) {
    return null;
  }

  return <>{props.children}</>;
});
