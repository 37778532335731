import React from "react";

import { useElementSize, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Checkbox, Divider, List, Tag } from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { OrdersListProps } from "..";
import {
  formatAddressWithName,
  formatDate,
  formatDateTime,
} from "../../../helper/formatter";
import { Customer, Order, OrderTechnicanRelation } from "../../../parse";
import { $heinzerling } from "../../../service";

export default observer<OrdersListProps>(
  ({ rows, loading, hideElevatorLink, linkToMobile, allowSelection }) => {
    const t = useTranslation();
    const navigate = useNavigate();
    const listRef = React.useRef<HTMLDivElement>(null);
    const { width, height } = useElementSize(listRef, 200);
    const [selectedOrders, setSelectedOrders] = React.useState<Array<string>>(
      []
    );

    const getRowKey = (row: Order | OrderTechnicanRelation) => {
      if (row instanceof Order) {
        return row.id + "_" + crypto.randomUUID();
      }
      if (row instanceof OrderTechnicanRelation) {
        return row.id + row.get("sys_app_id");
      }
      return crypto.randomUUID();
    };

    return (
      <div ref={listRef}>
        <List
          itemLayout={width < 600 ? "vertical" : "horizontal"}
          loading={loading}
          dataSource={rows}
          locale={{
            emptyText: t("app:orders.list_empty"),
          }}
          rowKey={(row) => getRowKey(row)}
          header={
            !allowSelection ? undefined : (
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Button
                  key="sign"
                  type="primary"
                  icon={<Icon icon="fa:signature" />}
                  children={t("app:admin.order.action_sign")}
                  disabled={selectedOrders.length === 0}
                  onClick={() => {
                    $heinzerling.mobile.signOrders(selectedOrders);
                  }}
                />
              </div>
            )
          }
          footer={
            <>
              <Divider />
              <div>
                <Icon icon="fa:check-double" style={{ color: "green" }} /> =
                Arbeiten abgeschlossen + Arbeitsbericht versandt
              </div>
              <div>
                <Icon icon="fa:check" style={{ color: "#ffc107" }} /> =
                Arbeitsbericht versandt
              </div>
              {/* <div>
            <Icon icon="fa:clock" /> = Keine der oberen Bedingungen
          </div> */}
            </>
          }
          renderItem={(row: Order | OrderTechnicanRelation) => {
            const auftrag = getOrder(row);
            const facility = getFacility(row);
            const customer = auftrag.get("customer") as Customer;
            const title = "#" + auftrag.no;
            const description1 = auftrag.documentText;
            const description2 = formatAddressWithName(facility || customer);
            const reldescription = getDescription(row);
            const date = getDate(row);
            const link = getLink(row, linkToMobile, auftrag);
            const technician = getTechnician(row);
            const checkboxDisabled = !!auftrag.get("sys_mailSent");

            return (
              <List.Item
                onClick={
                  checkboxDisabled
                    ? undefined
                    : (e) => {
                        e.preventDefault();
                        if (selectedOrders && setSelectedOrders) {
                          if (selectedOrders.includes(auftrag.id)) {
                            // Orderalready exists in selected orders
                            setSelectedOrders(
                              selectedOrders.filter(
                                (value) => value !== auftrag.id
                              )
                            );
                          } else {
                            // Add order to array of selected orders
                            setSelectedOrders([...selectedOrders, auftrag.id]);
                          }
                        }
                      }
                }
                actions={[
                  <>
                    {!!facility && !hideElevatorLink && (
                      <Button
                        key="elevator"
                        icon={<Icon icon="fa:external-link" />}
                        children={t("app:admin.elevators.view_title", {
                          name: facility.get("no") || "",
                        })}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/elevators/${facility.id}`);
                        }}
                      />
                    )}
                  </>,
                  //   <Button
                  //     key="print"
                  //     type="primary"
                  //     icon={<Icon icon="fa:print" />}
                  //     children={t("app:admin.order.action_print")}
                  //     onClick={() => {
                  //       navigate(`/print-order/${auftrag.id}`);
                  //     }}
                  //   />,
                  <Button
                    key="show"
                    type="primary"
                    // icon={<Icon icon="fa:print" />}
                    children={t("app:admin.order.action_show")}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(link);
                    }}
                  />,
                ]}
              >
                {/* <Link to={link}> */}
                {allowSelection ? (
                  <Checkbox
                    disabled={checkboxDisabled}
                    style={{ marginRight: 24 }}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    checked={selectedOrders.includes(auftrag.id)}
                  />
                ) : null}
                <List.Item.Meta
                  avatar={
                    !auftrag.get("local_moreWorkNecessary") &&
                    !!auftrag.get("sys_mailSent") ? (
                      <Icon
                        icon="fa:check-double"
                        style={{
                          color: "green",
                          fontSize: "16px",
                          height: "48px",
                          lineHeight: "48px",
                        }}
                      />
                    ) : auftrag.get("sys_mailSent") ? (
                      <Icon
                        icon="fa:check"
                        style={{
                          color: "#ffc107",
                          fontSize: "16px",
                          height: "48px",
                          lineHeight: "48px",
                        }}
                      />
                    ) : (
                      <Icon
                        icon="fa:clock"
                        style={{
                          fontSize: "16px",
                          height: "48px",
                          lineHeight: "48px",
                        }}
                      />
                    )
                  }
                  title={
                    <span>
                      {title} <Tag>{date}</Tag> <Tag>{auftrag.type}</Tag>
                    </span>
                  }
                  description={
                    <>
                      <div style={{ marginBottom: 8 }}>{description1}</div>
                      <div>{description2}</div>
                      {reldescription ? (
                        <div>
                          <b>{reldescription}</b>
                        </div>
                      ) : null}
                      <div>
                        {typeof technician !== "undefined" &&
                        technician !== null &&
                        typeof technician.get("name") !== "undefined" &&
                        typeof technician.get("name2") !== "undefined"
                          ? "Techniker: " +
                            technician.get("name2") +
                            " " +
                            technician.get("name")
                          : null}
                      </div>
                    </>
                  }
                />
              </List.Item>
            );
          }}
        />
      </div>
    );
  }
);

function getOrder(row: Order | OrderTechnicanRelation): Order {
  if (row instanceof Order) {
    return row;
  }

  if (row instanceof OrderTechnicanRelation) {
    return row.order!;
  }

  throw new Error("Invalid row data");
}

function getDescription(row: Order | OrderTechnicanRelation) {
  if (row instanceof Order) {
    return "";
  }

  if (row instanceof OrderTechnicanRelation) {
    return row.get("description");
  }

  throw new Error("Invalid row data");
}

function getLink(
  row: Order | OrderTechnicanRelation,
  linkToMobile: Boolean | undefined,
  auftrag: Order
) {
  if (row instanceof Order) {
    return linkToMobile
      ? `/phone/order/${auftrag.id}`
      : `/orders/${auftrag.id}`;
  }

  if (row instanceof OrderTechnicanRelation) {
    return linkToMobile
      ? `/phone/order/${auftrag.id}?rel=${row.id}`
      : `/orders/${auftrag.id}?rel=${row.id}`;
  }

  return "/";
}

function getFacility(row: Order | OrderTechnicanRelation) {
  if (row instanceof Order) {
    return row.facility;
  }

  if (row instanceof OrderTechnicanRelation) {
    if (typeof row.facility !== "undefined") {
      return row.facility;
    }
    return row.order?.facility;
  }

  throw new Error("Invalid row data");
}

function getTechnician(row: Order | OrderTechnicanRelation) {
  if (row instanceof Order) {
    return null;
  }

  if (row instanceof OrderTechnicanRelation) {
    return row.technican;
  }

  throw new Error("Invalid row data");
}

function getDate(row: Order | OrderTechnicanRelation): string | null {
  if (row instanceof Order) {
    return "Eingegangen am: " + formatDate(row.date);
  }

  if (row instanceof OrderTechnicanRelation) {
    return formatDateTime(row.start);
  }

  throw new Error("Invalid row data");
}
