import { HeinzerlingHelperService } from "./HeinzerlingHelperService";
import { HeinzerlingMobileService } from "./HeinzerlingMobileService";
import { HeinzerlingOfflineService } from "./HeinzerlingOfflineService";

export class HeinzerlingService {
  public sync = new HeinzerlingOfflineService(this);
  public mobile = new HeinzerlingMobileService(this);
  public helper = new HeinzerlingHelperService(this);

  init() {
    console.log("$heinzerling.init()");
  }
}

export const $heinzerling = new HeinzerlingService();
