import { Button, Drawer, Form, Input, Space } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { CreateItemProps } from "..";

export default observer<CreateItemProps>(({ state }) => {
  return (
    <Drawer
      title="Neuer Artikel"
      placement="bottom"
      open={!!state.open}
      onClose={() => (state.open = false)}
      height="571px"
    >
      <Form onFinish={(data) => state.save(data)} layout="vertical">
        <p>
          Über diesen Dialog können neue Artikel in der Datenbank registiert
          werden, die nicht bereits über orderbase in der Datenbank angelegt
          wurden.
        </p>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Bitte einen Namen angeben." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Einheit" name="unit">
          <Input />
        </Form.Item>
        <Form.Item
          label="Beschreibung"
          name="description4"
          help="Dieses Feld ist optional."
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" children="Speichern" />
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
});
