import { AdminLayout, Loading } from "@opendash/core";
import { Button, DatePicker, Form, Select, message } from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { $offlineService } from "../../features/offline";
import { OrderCreateState } from "../../features/orders";
import {
  Facility,
  Order,
  OrderTechnicanRelation,
  Technican,
} from "../../parse";
import { $heinzerling } from "../../service";

export const OrderCreatePage = observer(() => {
  const navigate = useNavigate();
  const state = useMemo(() => new OrderCreateState(), []);
  const [form] = Form.useForm();

  function getSelectedFacilityInfo() {
    if (state.SelectedFacility) {
      return (
        <>
          <p>
            Der Auftrag wird dem folgenden Kunden zugewiesen:{" "}
            {state.SelectedFacility.get("customer")?.get("name")}
          </p>
        </>
      );
    }
    return false;
  }

  function submit() {
    form.validateFields().then(async (values) => {
      const facility = await $heinzerling.sync
        .createQuery(Facility)
        .include("customer")
        .get(values["facility"]);

      const neworder = new Order();

      neworder.set("local_createdOnMobile", true);
      neworder.set("facility", facility);
      neworder.set("type", values["type"]);
      neworder.set("date", dayjs(values["dateandtime"]).toDate());
      neworder.set("no", "local-" + Date.now()); //TODO
      neworder.set("customer", facility.get("customer"));

      await $heinzerling.sync.saveObject(neworder);

      for (const technicianId of values.technicians) {
        const technician = await $heinzerling.sync
          .createQuery(Technican)
          .get(technicianId);

        const newordertechnicianrelation = new OrderTechnicanRelation();
        newordertechnicianrelation.set("technican", technician);
        newordertechnicianrelation.set("order", neworder);
        newordertechnicianrelation.set("local_hours", 1);
        newordertechnicianrelation.set(
          "start",
          dayjs(values["dateandtime"]).toDate()
        );

        await $heinzerling.sync.saveObject(newordertechnicianrelation);
      }

      form.resetFields();
      message.success(
        "Auftrag erfolgreich angelegt. Sie werden weitergeleitet.",
        5
      );

      if ($offlineService.online) {
        navigate(`/phone/order/${neworder.id}/view-overview`);
      } else {
        navigate("/phone/offline?action=order_created");
      }
    });
  }

  return (
    <AdminLayout contentPadding>
      {state.loading ? (
        <Loading message="" />
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item
            name="facility"
            label="Aufzug"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              options={state.facilitiesForSelect}
              filterOption={(input, option) => {
                return (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              onSelect={(value, option) => {
                const foundfacility = state.facilities.filter(
                  (facility) => facility.id === value
                )[0];

                if (foundfacility) {
                  state.SelectedFacility = foundfacility;
                }
              }}
            />
          </Form.Item>

          <Form.Item
            name="type"
            label="Auftragsart"
            rules={[{ required: true }]}
          >
            <Select
              options={state.orderTypes.map((ordertype) => ({
                value: ordertype.get("abbreviation"),
                label: `${ordertype.get("abbreviation")} - ${ordertype.get(
                  "label"
                )}`,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="dateandtime"
            label="Datum und Zeit"
            rules={[{ required: true }]}
            initialValue={dayjs().startOf("hour")}
          >
            <DatePicker
              showTime={{ format: "HH:mm" }}
              format="DD.MM.YYYY HH:mm"
              onChange={() => {}}
              onOk={() => {}}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="technicians"
            label="Monteur"
            initialValue={
              state.userTechnician ? [state.userTechnician.id] : null
            }
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              showSearch
              options={state.TechniciansForSelect}
              filterOption={(input, option) => {
                return (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            />
          </Form.Item>

          {getSelectedFacilityInfo()}

          <Button type="primary" onClick={() => submit()}>
            Auftrag anlegen
          </Button>
        </Form>
      )}
    </AdminLayout>
  );
});
