import Parse from "parse";

export interface TextTemplateAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  text: string;
  title: string;
}

export class TextTemplate extends Parse.Object<TextTemplateAttributes> {
  static className: string = "Heinzerling2_TextTemplate";

  constructor(data?: Partial<TextTemplateAttributes>) {
    super("Heinzerling2_TextTemplate", data as TextTemplateAttributes);
  }

  get text(): string {
    return super.get("text");
  }
  set text(value: string) {
    super.set("text", value);
  }
  get title(): string {
    return super.get("title");
  }
  set title(value: string) {
    super.set("title", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_TextTemplate", TextTemplate);
