import {
  AdminLayout,
  search,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import { Select } from "antd";
import { observer } from "mobx-react-lite";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React, { useEffect, useMemo, useState } from "react";
import { OrdersList } from "../../features/orders";
import {
  Customer,
  Facility,
  OrderTechnicanRelation,
  Technican,
} from "../../parse";

export const OrdersPage = observer(() => {
  const t = useTranslation();
  const [technicians, setTechnicians] = useState<Technican[]>([]);
  const [selectedTechnician, setSelectedTechnician] = useState<string[]>([]);
  const [searchString, setSearchString] = useUrlParam<string>(
    "query",
    "",
    "string"
  );

  const [hiddenQuery] = useUrlParam<string>("hiddenQuery", "", "string");

  const query = useMemo(
    () =>
      // new Parse.Query(Order)
      //   .exists("date")
      //   .descending("date")
      //   .include("customer")
      //   .include("facility")
      //   .limit(1000000),
      new Parse.Query(OrderTechnicanRelation)
        .exists("start")
        .descending("start")
        .include("order")
        //@ts-ignore
        .include("order.customer")
        //@ts-ignore
        .include("order.facility")
        .include("facility")
        .include("technican")
        .limit(1000000),
    []
  );

  useEffect(() => {
    new Parse.Query(Technican).ascending("name").find().then(setTechnicians);
  }, []);

  const entries = useParseQuery(query, {
    live: true,
    liveReload: true,
    reloadThrottle: 5000,
  });

  const rows = useMemo(
    () =>
      //hiddenQuery === null ? "" : hiddenQuery
      search(
        searchString === null ? "" : searchString,
        entries.result
          .filter((row) => !!row.get("order"))
          .filter((row) => {
            if (row.get("technican") === undefined) {
              return true;
            }

            return (
              selectedTechnician.length === 0 ||
              selectedTechnician.some((id) => id === row.get("technican").id)
            );
          }),
        (row: OrderTechnicanRelation) => {
          const customer = row.get("order")!.get("customer") as Customer;
          const facility = row.get("order")!.get("facility") as Facility;
          const technician = row.get("technican") as Technican;

          return {
            text: [
              row.get("order")!.get("no") || "",
              customer?.get("name")?.toLowerCase() || "",
              facility?.get("no")?.toString() || "",
              technician?.get("name")?.toLowerCase() || "",
            ],
            keys: {
              // monteur: technician?.get("name") || "",
              versendet: row.get("order")!.get("sys_mailSent") ? "ja" : "nein",
              abgeschlossen: row.get("order")!.get("sys_locked")
                ? "ja"
                : "nein",
            },
          };
        }
      ).sort((a, b) => {
        return (
          (b.get("start")?.valueOf() || 0) - (a.get("start")?.valueOf() || 0)
        );
      }),
    [
      entries.result.length,
      searchString,
      hiddenQuery,
      selectedTechnician.length,
    ]
  );

  const uniqueOrders = (rows: OrderTechnicanRelation[]) => {
    return Object.values(
      rows.reduce(
        (
          acc: Record<string, OrderTechnicanRelation>,
          otr: OrderTechnicanRelation
        ) => {
          if (!acc[otr.get("order")!.id]) {
            acc[otr.get("order")!.id] = otr;
          }
          return acc;
        },
        {} as Record<string, OrderTechnicanRelation>
      )
    );
  };

  return (
    <>
      <AdminLayout contentPadding>
        <AdminToolbar
          title={t("app:admin.order.title")}
          description={t("app:admin.order.description")}
          // actions={[
          //   <Button
          //     key="new"
          //     type="primary"
          //     icon={<Icon icon="fa:plus" />}
          //     children={t("app:admin.order.action_new")}
          //     onClick={() => {
          //       $parse.ui.createObject("Auftrag").then((objectId) => {
          //         entries.reload();
          //       });
          //     }}
          //   />,
          // ]}
          searchInitial={searchString}
          onSearch={setSearchString}
          children={
            <Select
              mode="multiple"
              value={selectedTechnician}
              options={technicians.map((technician) => {
                return {
                  label: technician.get("name2") + " " + technician.get("name"),
                  value: technician.id,
                  key: technician.id,
                };
              })}
              onChange={(value) => setSelectedTechnician(value)}
              style={{ width: "100%", marginBottom: 8 }}
            />
          }
        />
        <OrdersList rows={uniqueOrders(rows)} loading={entries.loading} />
        {/* <Detector
          offlineChildren={
            <Empty
              style={{ marginTop: 24 }}
              children="Sie sind offline. Die Aufträge können nicht angezeigt werden."
              description={<Title level={5}>OFFLINE</Title>}
            />
          }
          onlineChildren={
            <>
              
            </>
          }
        /> */}
      </AdminLayout>
    </>
  );
});
