import { Icon } from "@opendash/icons";
import { Button, Spin } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { TabSwitch } from "..";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { $heinzerling } from "../../../service";
import { OrderState, PageActions } from "../../orders";

const TabContentContainer = styled.div`
  // border-left: 5px solid red;

  padding: 16px;

  overflow: auto;
  height: calc(100vh - var(--opendash-header-height) - 64px - 40px);
  width: 100%;

  .ant-spin {
    width: 100%;
    text-align: center;
    padding: 50px 0;
  }

  .ant-page-header {
    padding: 0 0 16px 0;
  }
`;

export default observer<{ state: OrderState }>(function TabContent({ state }) {
  const navigate = useNavigate();

  if (state.loading) {
    return (
      <TabContentContainer>
        <Spin />
      </TabContentContainer>
    );
  }

  if (state.notFound) {
    return (
      <ErrorMessage
        icon="fa:exclamation-circle"
        title="Auftrag nicht gefunden"
        message="Unter dieser URL konnte kein Auftrag gefunden werden."
      />
    );
  }

  return (
    <TabContentContainer>
      <PageActions
        title={state.currentMenu?.label}
        subTitle={state.title}
        onBack={() => {
          navigate(
            state.backLink,
            typeof state.backLink === "string" ? { replace: true } : undefined
          );
        }}
        extra={
          <Button
            type="primary"
            size="large"
            shape="circle"
            disabled={state.disabled}
            onClick={() => {
              $heinzerling.mobile.signOrders([state.id]);
            }}
            icon={<Icon icon="fa:signature" />}
          />
        }
      />
      <TabSwitch state={state} />
    </TabContentContainer>
  );
});
