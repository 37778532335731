import { makeAutoObservable, runInAction } from "@opendash/state";
import { message } from "antd";
import { Order } from "../../../parse";
import { $heinzerling } from "../../../service";

export class UpdateTextsState {
  private order: Order;
  public finishedText: string = "";
  public documentText: string = "";
  public local_moreWorkNecessary: boolean = false;
  public local_emergencyService: boolean = false;
  public local_vandalism: boolean = false;
  public local_overvoltageDamage: boolean = false;

  public dirty: boolean = false;

  public insertTemplate: "finishedText" | "documentText" | null = null;

  constructor(order: Order) {
    this.order = order;

    makeAutoObservable(this);

    this.reset();
  }

  setFinishedText(v: string) {
    this.finishedText = v;
    this.dirty = true;
  }

  setDocumentText(v: string) {
    this.documentText = v;
    this.dirty = true;
  }

  setInstertTemplate(v: UpdateTextsState["insertTemplate"]) {
    this.insertTemplate = v;
  }

  instertTemplateText(v: string) {
    if (this.insertTemplate === "finishedText") {
      this.finishedText += v;
    }

    if (this.insertTemplate === "documentText") {
      this.documentText += v;
    }

    this.insertTemplate = null;
    this.dirty = true;
  }

  public reset() {
    this.finishedText = this.order.local_finishedText || "";
    this.documentText = this.order.local_documentText || "";
    this.local_moreWorkNecessary = this.order.local_moreWorkNecessary || false;
    this.local_emergencyService = this.order.local_emergencyService || false;
    this.local_vandalism = this.order.local_vandalism || false;
    this.local_overvoltageDamage = this.order.local_overvoltageDamage || false;
    this.dirty = false;
  }

  public async save() {
    try {
      this.order.set("local_finishedText", this.finishedText);
      this.order.set("local_documentText", this.documentText);
      this.order.set("local_moreWorkNecessary", this.local_moreWorkNecessary);
      this.order.set("local_emergencyService", this.local_emergencyService);
      this.order.set("local_vandalism", this.local_vandalism);
      this.order.set("local_overvoltageDamage", this.local_overvoltageDamage);

      await $heinzerling.sync.saveObject(this.order);

      runInAction(() => {
        this.dirty = false;
      });

      message.success("Gespeichert");
    } catch (error) {
      message.error("Speichern fehlgeschlagen");
    }
  }
}
