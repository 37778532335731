import { useTranslation } from "@opendash/i18n";
import { Button } from "antd";
import * as React from "react";

import { $parse } from "@opendash/plugin-parse";
import styled from "styled-components";
import { PrintViewProps } from "..";

const Container = styled.div`
  width: 100%;
`;

const Actions = styled.div`
  margin-bottom: 20px;
  @media (max-width: 600px) {
    button {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  @media (min-width: 601px) {
    button {
      margin-right: 10px;
    }
    .mobile-only {
      display: none;
    }
  }
`;

const Paper = styled.div`
  iframe {
    width: 100%;
    border: 0;
  }

  @media screen {
    max-width: 1000px;
    margin: 10px auto;

    padding: 10px;

    background: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export default React.memo<PrintViewProps>(function PrintView({
  title,
  preview,
  download,
}) {
  const t = useTranslation();

  const session = $parse.user.sessionToken();
  const qs = `?session=${session}`;

  return (
    <Container>
      <Actions>
        {title && <h1>{title}</h1>}
        {download && (
          <Button
            type="primary"
            children={t("app:print.action")}
            onClick={() => {
              // window.print();

              window.open(download + qs, "_blank");
            }}
          />
        )}

        {preview && (
          <Button
            className="mobile-only"
            type="primary"
            children={t("app:print.action_preview")}
            onClick={() => {
              // window.print();

              window.open(preview + qs, "_blank");
            }}
          />
        )}
      </Actions>
      <Paper>
        <iframe
          src={preview + qs}
          onLoad={(e) => {
            const frame = e.target as HTMLIFrameElement;

            frame.height = (frame.offsetWidth * 10) / 7 + "px";
            // frame.width = frame.offsetWidth + "px";

            try {
              frame.height =
                frame?.contentWindow?.document.body.scrollHeight + "px";
            } catch (error) {
              // CORS Error
            }
          }}
        />
      </Paper>
    </Container>
  );
});
