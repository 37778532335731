"use client";
import { Icon } from "@opendash/icons";
import { Button, Col, Row, Space, Typography } from "antd";
import React from "react";
import { PageActionsType } from "..";

const { Text } = Typography;

export default (props: PageActionsType) => {
  return (
    <>
      <Row style={{ marginBottom: 24 }}>
        <Col>
          <Row gutter={8} align={"middle"}>
            {props.onBack ? (
              <Col>
                <Button
                  type="text"
                  icon={<Icon icon="fa:arrow-left" />}
                  onClick={() => props.onBack()}
                />
              </Col>
            ) : null}
            <Col>
              <Text strong style={{ fontSize: "1.3rem" }}>
                {props.title}
              </Text>
            </Col>
            <Col>
              {props.subTitle ? (
                <Text type="secondary">{props.subTitle}</Text>
              ) : null}
            </Col>
            {props.footer ? <Col>{props.footer}</Col> : null}
          </Row>
        </Col>
        <Col flex="auto" style={{ textAlign: "right" }}>
          <Space>{props.extra}</Space>
        </Col>
      </Row>
      <Row>
        {React.Children.map(props.children, (child, index) => (
          <span key={index} className="page-actions_meta">
            {child}
          </span>
        ))}
      </Row>
    </>
  );
};
