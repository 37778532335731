import { AdminLayout } from "@opendash/core";
import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { OrderState } from "../../features/orders";
import { TabContent, TabLabel } from "../../features/phone";
import { useQuery } from "../../hooks/useQuery";

export const PhoneOrderPage = observer(() => {
  const query = useQuery();
  const { id, tab } = useParams();
  const navigate = useNavigate();

  const state = useMemo(
    () => new OrderState(id!, query.get("rel") ? query.get("rel")! : undefined),
    [id]
  );

  useEffect(() => {
    if (!tab) {
      navigate(state.menu[0]?.url, { replace: true });
    } else {
      state.setTab(tab);
    }
  }, [tab]);

  return (
    <AdminLayout>
      <Tabs
        style={{ height: "100%" }}
        centered
        animated
        tabPosition="bottom"
        activeKey={tab}
        items={state.menu.map((menuitem) => {
          return {
            key: menuitem.key,
            label: <TabLabel menuitem={menuitem} />,
            children: <TabContent state={state} />,
          };
        })}
        onTabClick={(key) => {
          const m = state.menu.find((m) => m.key === key);

          if (m) {
            navigate(m.url, { replace: true });
          }
        }}
      />
    </AdminLayout>
  );
});
