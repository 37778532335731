import { useTranslation } from "@opendash/i18n";
import {
  Button,
  Collapse,
  DatePicker,
  Drawer,
  Form,
  Select,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { SignatureDrawerState } from "..";
import { $heinzerling } from "../../../service";
import { PrintView } from "../../orders";
import SignatureCanvas from "./SignatureCanvas";

export default observer(() => {
  const t = useTranslation();
  const ids = $heinzerling.mobile.signIds;
  // const inputRef = useRef<InputRef>(null);

  if (!ids) throw new Error("Invalid State");

  // const [email, setEmail] = useState("");

  const state = useMemo(() => new SignatureDrawerState(ids), [ids]);

  const navigate = useNavigate();

  // const addItem = (
  //   e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  // ) => {
  //   e.preventDefault();
  //   if (!state.emailregex.test(email) || email.length < 1) {
  //     return;
  //   }
  //   state.emails = [
  //     ...state.emails,
  //     {
  //       value: email,
  //       label: email,
  //       key: crypto.randomUUID(),
  //     },
  //   ];
  //   setEmail("");
  //   setTimeout(() => {
  //     inputRef.current?.focus();
  //   }, 0);
  // };

  return (
    <Drawer
      title={state.step.label}
      open={true}
      closable={true}
      placement="bottom"
      height="100%"
      onClose={() => {
        $heinzerling.mobile.signOrders(null);
      }}
      extra={
        state.isLastStep ? null : (
          <Button
            type="primary"
            disabled={state.disabled}
            onClick={() => state.next()}
            children="Weiter"
          />
        )
      }
    >
      {state.step.key === "overview" && (
        <Collapse
          key={state.orders.map((o) => o.no).join("-")}
          // defaultActiveKey={[state.orders[0]?.no]}
          items={state.orders.map((order) => {
            const pdf = order.get("sys_pdf")?.url();
            return {
              key: order.no,
              label: "Auftrag " + order.no,
              children: (
                <>
                  {/* <Descriptions
                    title="Auftragsinformationen"
                    bordered
                    size="small"
                    style={{ marginBottom: 30 }}
                    column={1}
                  >
                    <Descriptions.Item label="Art" children={order.type} />
                    <Descriptions.Item
                      label="Datum"
                      children={formatDate(order.date)}
                    />
                    <Descriptions.Item
                      label="Kunde"
                      children={formatName(order.customer)}
                    />
                    <Descriptions.Item
                      label="Kundenanschrift"
                      children={formatAddress(order.customer, true)}
                    />
                    <Descriptions.Item
                      label={t("app:classes.Order.fields.referenceContact")}
                      children={order.referenceContact || "-"}
                    />
                    <Descriptions.Item
                      label={t("app:classes.Order.fields.referenceNo")}
                      children={order.referenceNo || "-"}
                    />
                    <Descriptions.Item
                      label={t("app:classes.Order.fields.referenceDate")}
                      children={formatDate(order.referenceDate) || "-"}
                    />
                  </Descriptions> */}
                  {pdf && (
                    <PrintView
                      // title={t("app:orders.workreport.title")}
                      preview={pdf}
                      // download={pdf}
                    />
                  )}

                  {!pdf && (
                    <PrintView
                      // title={t("app:orders.workreport.title_preview")}
                      preview={`https://heinzerling-print-service-dev.apps.openinc.dev/render/auftragsbestaetigung/${order.id}`}
                      // download={`https://heinzerling-print-service-dev.apps.openinc.dev/print/auftragsbestaetigung/${order.id}`}
                    />
                  )}
                </>
              ),
            };
          })}
        />
      )}

      {state.step.key === "customer" && (
        <SignatureCanvas
          key={state.step.key}
          value={state.customerSignature}
          onChange={(v) => state.setCustomerSignature(v)}
        />
      )}

      {state.step.key === "technican" && (
        <SignatureCanvas
          key={state.step.key}
          value={state.technicanSignature}
          onChange={(v) => state.setTechnicanSignature(v)}
        />
      )}

      {state.step.key === "finish" && (
        <Form
          key={state.step.key}
          labelAlign="left"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={(data) => {
            state.save(data).then(() => {
              navigate("/phone/calendar");
            });
          }}
        >
          <Typography.Title children="Arbeitsbericht" level={4} />
          {/* <Form.Item
            name="local_mailto"
            label="E-Mail-Adresse des Kunden"
            help="Der Arbeitsbericht wird an diese E-Mail Adresse versandt."
          >
            <Input />
          </Form.Item> */}

          <Form.Item
            name="local_mailto"
            label={t("app:signature.email.label")}
            help={t("app:signature.email.help")}
          >
            <Select
              mode="tags"
              allowClear
              optionLabelProp="label"
              options={[
                {
                  label: t("app:signature.email.dropdown.saveditems"),
                  options: [...state.emails],
                },
              ]}
              // dropdownRender={(menu) => (
              //   <>
              //     {menu}
              //     <Divider style={{ margin: "8px 0" }} />
              //     <Row
              //       gutter={[8, 8]}
              //       justify="space-between"
              //       style={{ padding: "0 8px 4px" }}
              //     >
              //       <Col span={12}>
              //         <Input
              //           placeholder={t("app:signature.email.add.placeholder")}
              //           ref={inputRef}
              //           value={email}
              //           onChange={(
              //             event: React.ChangeEvent<HTMLInputElement>
              //           ) => {
              //             setEmail(event.target.value);
              //           }}
              //         />
              //       </Col>
              //       <Col>
              //         <Button
              //           type="text"
              //           icon={<Icon icon="fa:plus" />}
              //           onClick={addItem}
              //         >
              //           {t("app:global.button.add_label")}
              //         </Button>
              //       </Col>
              //     </Row>
              //   </>
              // )}
            />
          </Form.Item>

          <Form.Item
            name="local_signatureTimestamp"
            label="Datum der Unterschrift"
            help="Mit dieser Einstellung kann der Zeitpunkt der Unterschrift überschrieben werden."
            initialValue={dayjs()}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={state.sending}
              children="Absenden"
            />
          </Form.Item>
        </Form>
      )}
    </Drawer>
  );
});
