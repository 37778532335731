import { message } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import Parse from "parse";
import { OrderTechnicanRelation, Technican } from "../../../parse";
import { $heinzerling } from "../../../service";
import { OrderState } from "../../orders/states/OrderState";

export class UpdateTechnicansState {
  state: OrderState;

  public updateDialog: OrderTechnicanRelation | null = null;
  public updateDialogTechnican: Technican | null = null;
  public updateDialogDate: Date | null = null;
  public updateDialogHours: number | null = null;
  public updateDialogOutsideBusinessHours: boolean = false;
  public updateDialogHideFromCustomer: boolean = false;

  private technicans: Technican[] = [];
  private listChange = 1;

  constructor(state: OrderState) {
    this.state = state;

    makeAutoObservable(this);
  }

  get list() {
    this.listChange;

    return this.state.technicans
      .filter((x) => !x.local_deleted)
      .sort((a, b) => (a.start?.valueOf() || 0) - (b.start?.valueOf() || 0));
  }

  get technicanOptions() {
    return this.technicans.map((t) => ({
      value: t.id,
      label: `${t.name}, ${t.name2}`,
    }));
  }

  public setUpdateDialog(ir: UpdateTechnicansState["updateDialog"]) {
    this.updateDialog = ir;

    this.setUpdateDialogTechnican(ir?.technican || null);
    this.setUpdateDialogDate(ir?.start || null);
    this.setUpdateDialogHours(ir?.local_hours ?? ir?.hours ?? 0);
    this.setUpdateDialogOutsideBusinessHours(ir?.outsideBusinessHours ?? false);
    this.setUpdateDialogHideFromCustomer(ir?.local_hideFromCustomer ?? false);

    this.fetchTechnicans();
  }

  public setUpdateDialogTechnican(
    v: UpdateTechnicansState["updateDialogTechnican"] | string
  ) {
    if (typeof v === "string") {
      const item = this.technicans.find((i) => i.id === v) || null;

      this.updateDialogTechnican = item;
    } else {
      this.updateDialogTechnican = v;
    }
  }

  public setUpdateDialogHours(v: UpdateTechnicansState["updateDialogHours"]) {
    this.updateDialogHours = v;
  }

  public setUpdateDialogDate(v: UpdateTechnicansState["updateDialogDate"]) {
    this.updateDialogDate = v;
  }

  public setUpdateDialogOutsideBusinessHours(
    v: UpdateTechnicansState["updateDialogOutsideBusinessHours"]
  ) {
    this.updateDialogOutsideBusinessHours = v;
  }

  public setUpdateDialogHideFromCustomer(
    v: UpdateTechnicansState["updateDialogHideFromCustomer"]
  ) {
    this.updateDialogHideFromCustomer = v;
  }

  public async create() {
    if (!this.state.order) throw new Error("Invalid State");

    const user = await $heinzerling.sync.getUser();
    if (typeof user === "undefined" || user === null) {
      throw new Error("Invalid State");
    }

    const now = new Date();

    const item = new OrderTechnicanRelation({
      order: this.state.order,
      technican: user?.get("technican"),
      start: now,
      outsideBusinessHours: now.getHours() > 20,
      local_hours: 1,
    });

    runInAction(() => {
      this.state.technicans.push(item);
    });

    this.setUpdateDialog(item);
  }

  public async saveUpdateDialog() {
    if (!this.updateDialog) return null;

    if (this.updateDialogTechnican === null) {
      return message.error("Es muss ein Artikel ausgewählt werden!");
    }

    if (this.updateDialogDate === null) {
      return message.error("Es muss ein Datum gewählt werden!");
    }

    if (this.updateDialogHours === null) {
      return message.error("Es müssen Stunden eingegeben werden!");
    }

    if (this.updateDialogOutsideBusinessHours === null) {
      return message.error(
        "Es muss ausgewählt werden, ob die Stunden außerhalb der Geschäftszeiten liegen!"
      );
    }

    if (this.updateDialogHideFromCustomer === null) {
      return message.error(
        "Es muss ausgewählt werden, ob die Stunden für den Kunden sichtbar sind!"
      );
    }

    try {
      this.updateDialog.set("technican", this.updateDialogTechnican!);
      this.updateDialog.set("start", this.updateDialogDate!);
      this.updateDialog.set("local_hours", this.updateDialogHours!);
      this.updateDialog.set(
        "outsideBusinessHours",
        this.updateDialogOutsideBusinessHours!
      );

      this.updateDialog.set(
        "local_hideFromCustomer",
        this.updateDialogHideFromCustomer!
      );

      await $heinzerling.sync.saveObject(this.updateDialog);

      message.success("Eintrag wurde gespeichert.");

      runInAction(() => {
        this.updateDialog = null;
        this.updateDialogTechnican = null;
        this.updateDialogHours = null;
        this.updateDialogDate = null;
        this.updateDialogOutsideBusinessHours = false;
      });
    } catch (error) {
      if (error instanceof Parse.Error) {
        message.error(
          "Speichern fehlgeschlagen: " + error.code + ": " + error.message
        );
      } else {
        message.error("Speichern fehlgeschlagen: Unbekannter Fehler");
      }
    }
    return null;
  }

  public async destoryUpdateDialog() {
    if (this.updateDialog) {
      this.updateDialog.set("local_hours", 0);

      await $heinzerling.sync.saveObject(this.updateDialog);

      //Can't delete object, because orderbase sync does not allow for deletion yet
      // await $heinzerling.sync.deleteObject(this.updateDialog);

      this.setUpdateDialog(null);

      message.success("Eintrag gelöscht.");

      runInAction(() => {
        this.listChange += 1;
      });
    }
  }

  private async fetchTechnicans() {
    if (this.technicans.length > 0) return;

    const technicans = await $heinzerling.sync
      .createQuery(Technican)
      .ascending("name")
      .limit(1000000)
      .find();

    runInAction(() => {
      this.technicans = technicans;
    });
  }
}
