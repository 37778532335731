import { Button, Divider, List, Space } from "antd";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import {
  AddItemDrawer,
  AddItemState,
  CreateItemDrawer,
  CreateItemState,
  PartsListEntry,
  TabProps,
  TabState,
} from "../../items";

export default observer<TabProps>(function UpdateItemsTab({ orderstate }) {
  if (!orderstate.order) throw new Error("Invalid State");

  const createitemstate = useMemo(() => new CreateItemState(), []);
  const additemstate = useMemo(() => new AddItemState(orderstate), []);

  const state = useMemo(
    () => new TabState(orderstate),
    [createitemstate.open, additemstate.open]
  );

  return (
    <>
      <List
        dataSource={state.list}
        renderItem={(ir) => {
          return (
            <List.Item>
              <PartsListEntry oir={ir} orderstate={orderstate} />
            </List.Item>
          );
        }}
      />

      <Divider />

      <Space>
        <Button
          type="primary"
          disabled={orderstate.disabled}
          children="Artikel hinzufügen"
          onClick={() => (additemstate.open = true)}
        />

        <Button
          disabled={orderstate.disabled}
          children="Neuen Artikel anlegen"
          onClick={() => (createitemstate.open = true)}
        />
      </Space>

      <CreateItemDrawer state={createitemstate} />

      <AddItemDrawer state={additemstate} />
    </>
  );
});
